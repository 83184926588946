import { useCallback, useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { IHotspot } from "../../models/hotspot.models";
import { Params, useNavigate, useParams } from "react-router";
import { VTHTMLHotspotViewerMesh, VTHTMLHotspotWithControlsMesh } from "@virtus-tech-repository/virtus-tech-repository";
import {
    setCurrentAttachedHotspot,
    setCurrentAudio,
    setCurrentFeedbackImage,
    setCurrentFeedbackInput,
    setCurrentFeedbackVideo,
    setCurrentHotspot,
} from "../../store/slices/current.slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Vector3 } from "three";
import { IVTHotspotProps } from "./VTHotspot";
import { setHotspotActions } from "../../store/slices/tracking.slice";

function hotspotHasPostClickContent(hotspot: IHotspot): boolean {
    let hasPostClickContent = false;
    if (
        hotspot.contents &&
        (Boolean(hotspot.contents.postClickContent?.icon) || Boolean(hotspot.contents.postClickContent?.title))
    ) {
        hasPostClickContent = true;
    }

    if (
        hotspot.contents &&
        hotspot.contents.postClickContent?.contentData &&
        hotspot.contents.postClickContent?.contentData.replace(/\s+/g, "") !== `<divclass="rte-container"></div>`
    ) {
        hasPostClickContent = true;
    }

    return hasPostClickContent;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Renders the different hotspots
export function VTHotspotHTMLWrapper({
    hotspot,
    handleSaveHotspotLocation,
    handleDeleteHotspotLocation,
    handleProceedVideo,
    handleChangeScene,
    currentAttachedObject,
    setCurrentAttachedObject,
    videoCurrentState,
}: IVTHotspotProps) {
    const ref: any = useRef<any | null>();
    const navigate = useNavigate();
    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();
    const dispatch = useAppDispatch();

    const {
        currentAttachedHotspot,
        currentHotspot,
        currentAudio,
        currentFeedbackImage,
        currentFeedbackVideo,
        currentFeedbackInput,
        currentQuestion,
    } = useAppSelector((state) => state.currentReducer);

    const vtHtmlHotspotContainerRef = useRef<THREE.Mesh | null>(null);

    const [openHotspot, setOpenHotspot] = useState<boolean>(false);
    const [proceedMoveScenes, setProceedMoveScenes] = useState<string | undefined>(undefined);

    // this takes a hotspot and see's if it matches a current attached hotspot (at the time of dropped into scene),
    // then applies the setCurrentAttachedObject ref of that hotspot (child) so it can be positioned
    useEffect(() => {
        if (currentAttachedHotspot && currentAttachedHotspot.id && currentAttachedHotspot.id === hotspot.id) {
            setCurrentAttachedObject(ref);

            // is this line of code here doing anything?
            dispatch(setCurrentAttachedHotspot({} as IHotspot));
        }
    }, [currentAttachedHotspot]);

    useEffect(() => {
        if (ref.current) {
            ref.current.lookAt(0, 0, 0);
        }
    });

    function handleEditHotspotLocation() {
        setCurrentAttachedObject(ref);
    }

    function handleCancelHotspotLocation() {
        setCurrentAttachedObject(undefined);
        // if hotspot is mounted for first time...
        // delete it from the scene in the current scenario
    }

    useEffect(() => {
        if (!hotspotHasPostClickContent(hotspot)) {
            setOpenHotspot(false);
        }
    }, [hotspot]);

    const hotspotIsSelected = currentHotspot.currentHotspot && currentHotspot.currentHotspot.id === hotspot.id;

    return (
        <group ref={ref} position={new THREE.Vector3(hotspot.location?.x, hotspot.location?.y, hotspot.location?.z)}>
            <mesh
                scale={25 * (hotspot.styles?.postClickStyles?.scale ? hotspot.styles.postClickStyles.scale : 1)}
                ref={vtHtmlHotspotContainerRef}
            >
                {!openHotspot && hotspot.contents ? (
                    <VTHTMLHotspotViewerMesh
                        // transform={false} // to face camera set transform to false
                        editIconAsSettings={hotspotIsSelected}
                        borderOn={hotspotIsSelected}
                        borderColour={hotspot.styles?.preClickStyles?.colours?.background}
                        // ref={onRefChange}
                        position={new Vector3(0, 0, 0)}
                        title={hotspot.contents.preClickContent.title}
                        // @ts-ignore
                        icon={hotspot.contents.preClickContent.icon}
                        scale2d={hotspot.styles?.preClickStyles?.scale}
                        scale={0.5}
                        vtRichTextEditorContent={
                            hotspot.contents.preClickContent.contentData &&
                            hotspot.contents.preClickContent.contentData.replace(/\s+/g, "") !==
                                `<divclass="rte-container"></div>`
                                ? {
                                      content: hotspot.contents.preClickContent.contentData,
                                  }
                                : undefined
                        }
                        onClick={() => {
                            if (videoCurrentState === "Question") {
                                dispatch(
                                    setHotspotActions({
                                        hotspotId: hotspot.id,
                                        action: "Clicked",
                                        date: new Date(),
                                        questionId: currentQuestion.id,
                                    }),
                                );
                            } else {
                                dispatch(
                                    setHotspotActions({ hotspotId: hotspot.id, action: "Clicked", date: new Date() }),
                                );
                            }
                            if (
                                hotspot.contents.postClickContent &&
                                (hotspot.contents.postClickContent?.title ||
                                    hotspot.contents.postClickContent?.icon ||
                                    hotspot.contents.postClickContent?.contentData)
                            ) {
                                setOpenHotspot(true);
                            }
                            if (ref.current && hotspot.styles?.postClickStyles?.lookAt) {
                                ref.current.lookAt(
                                    hotspot.styles?.postClickStyles.lookAt.x,
                                    hotspot.styles?.postClickStyles.lookAt.y,
                                    hotspot.styles?.postClickStyles.lookAt.z,
                                );
                            }
                            if (hotspot.actions?.appFeedback?.audio) {
                                dispatch(setCurrentAudio(hotspot.actions?.appFeedback?.audio));
                            }
                            if (hotspot.actions?.appFeedback?.video) {
                                dispatch(setCurrentFeedbackVideo(hotspot.actions?.appFeedback?.video));
                            }
                            if (hotspot.actions?.appFeedback?.image) {
                                dispatch(setCurrentFeedbackImage(hotspot.actions?.appFeedback?.image));
                            }
                            if (hotspot.actions?.appFeedback?.input) {
                                dispatch(
                                    setCurrentFeedbackInput({
                                        hotspotId: hotspot.id,
                                        question: hotspot.actions?.appFeedback?.input,
                                    }),
                                );
                            }
                            if (handleProceedVideo && hotspot.actions?.appFeedback?.proceed) {
                                handleProceedVideo(hotspot.actions?.appFeedback?.delay);
                            }
                            if (hotspot.actions?.navigate) {
                                if (hotspot.actions?.navigate === "/") {
                                    navigate("/");
                                } else {
                                    window.open(hotspot.actions?.navigate);
                                }
                            }
                            if (hotspot.actions?.autoClose) {
                                const timer = setTimeout(
                                    () => {
                                        setOpenHotspot(false);
                                    },
                                    hotspot.actions?.autoClose * 1000,
                                );
                                return () => clearTimeout(timer);
                            }
                            if (handleChangeScene && hotspot.actions?.movement?.sceneId) {
                                if (hotspot.actions?.movement?.sceneId !== "Scene Undefined") {
                                    handleChangeScene(
                                        hotspot.actions?.movement?.sceneId,
                                        hotspot.actions?.movement?.delay,
                                    );
                                }
                            }
                        }}
                        handleEditHotspotLocation={handleEditHotspotLocation}
                        handleSaveHotspotLocation={() => {
                            handleSaveHotspotLocation &&
                                handleSaveHotspotLocation(
                                    hotspot.id,
                                    currentAttachedObject && currentAttachedObject.current
                                        ? currentAttachedObject.current.position
                                        : "o",
                                    videoCurrentState,
                                );
                        }}
                        handleCancelHotspotLocation={handleCancelHotspotLocation}
                        handleDeleteHotspotLocation={() => {
                            handleDeleteHotspotLocation && handleDeleteHotspotLocation(hotspot.id);
                        }}
                        handleEditHotspot={() => {
                            if (currentHotspot.currentHotspot && currentHotspot.currentHotspot.id === hotspot.id) {
                                dispatch(
                                    setCurrentHotspot({
                                        currentHotspot: hotspot,
                                        open: true,
                                    }),
                                );
                            } else {
                                dispatch(
                                    setCurrentHotspot({
                                        currentHotspot: hotspot,
                                        open: false,
                                    }),
                                );
                            }
                        }}
                        moving={currentAttachedObject === ref}
                    />
                ) : (
                    <VTHTMLHotspotViewerMesh
                        editIconAsSettings={hotspotIsSelected}
                        borderOn={hotspotIsSelected}
                        borderColour={hotspot.styles?.postClickStyles?.colours?.background}
                        position={new Vector3(0, 0, 0)}
                        title={hotspot.contents ? hotspot.contents.postClickContent?.title : undefined}
                        // @ts-ignore
                        icon={hotspot.contents ? hotspot.contents.postClickContent?.icon : undefined}
                        scale={0.5}
                        scale2d={hotspot.styles?.postClickStyles?.scale}
                        vtRichTextEditorContent={
                            hotspot.contents &&
                            hotspot.contents.postClickContent?.contentData &&
                            hotspot.contents.postClickContent.contentData.replace(/\s+/g, "") !==
                                `<divclass="rte-container"></div>`
                                ? {
                                      content: hotspot.contents.postClickContent?.contentData,
                                  }
                                : undefined
                        }
                        onClick={() => {
                            if (videoCurrentState === "Question") {
                                dispatch(
                                    setHotspotActions({
                                        hotspotId: hotspot.id,
                                        action: "Clicked",
                                        date: new Date(),
                                        questionId: currentQuestion.id,
                                    }),
                                );
                            } else {
                                dispatch(
                                    setHotspotActions({ hotspotId: hotspot.id, action: "Clicked", date: new Date() }),
                                );
                            }
                            if (handleChangeScene && hotspot.actions?.movement?.sceneId) {
                                if (hotspot.actions?.movement?.sceneId !== "Scene Undefined") {
                                    handleChangeScene(
                                        hotspot.actions?.movement?.sceneId,
                                        hotspot.actions?.movement?.delay,
                                    );
                                }
                            }
                            if (hotspot.actions?.appFeedback?.audio) {
                                dispatch(setCurrentAudio(hotspot.actions?.appFeedback?.audio));
                            }
                            if (hotspot.actions?.appFeedback?.video) {
                                dispatch(setCurrentFeedbackVideo(hotspot.actions?.appFeedback?.video));
                            }
                            if (hotspot.actions?.appFeedback?.image) {
                                dispatch(setCurrentFeedbackImage(hotspot.actions?.appFeedback?.image));
                            }
                            if (hotspot.actions?.appFeedback?.input) {
                                dispatch(
                                    setCurrentFeedbackInput({
                                        hotspotId: hotspot.id,
                                        question: hotspot.actions?.appFeedback?.input,
                                    }),
                                );
                            }
                            if (handleProceedVideo && hotspot.actions?.appFeedback?.proceed) {
                                handleProceedVideo(hotspot.actions?.appFeedback?.delay);
                            }
                            if (hotspot.actions?.navigate) {
                                if (hotspot.actions?.navigate === "/") {
                                    navigate("/");
                                } else {
                                    window.open(hotspot.actions?.navigate);
                                }
                            }
                        }}
                        onClose={
                            hotspot.actions?.closeable
                                ? () => {
                                      setOpenHotspot(false);
                                  }
                                : undefined
                        }
                        handleEditHotspotLocation={handleEditHotspotLocation}
                        handleSaveHotspotLocation={() => {
                            handleSaveHotspotLocation &&
                                handleSaveHotspotLocation(
                                    hotspot.id,
                                    currentAttachedObject && currentAttachedObject.current
                                        ? currentAttachedObject.current.position
                                        : "o",
                                    videoCurrentState,
                                );
                        }}
                        handleCancelHotspotLocation={handleCancelHotspotLocation}
                        handleDeleteHotspotLocation={() => {
                            handleDeleteHotspotLocation && handleDeleteHotspotLocation(hotspot.id);
                        }}
                        handleEditHotspot={() => {
                            if (currentHotspot.currentHotspot && currentHotspot.currentHotspot.id === hotspot.id) {
                                dispatch(
                                    setCurrentHotspot({
                                        currentHotspot: hotspot,
                                        open: true,
                                    }),
                                );
                            } else {
                                dispatch(
                                    setCurrentHotspot({
                                        currentHotspot: hotspot,
                                        open: false,
                                    }),
                                );
                            }
                        }}
                        moving={currentAttachedObject === ref}
                    />
                )}
            </mesh>
        </group>
    );
}
