/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 .\torso_skeliton.glb -t
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
    nodes: {
        coccyx: THREE.Mesh;
        costillas: THREE.Mesh;
        externon: THREE.Mesh;
        omoplato: THREE.Mesh;
        pelvic_girdle: THREE.Mesh;
        spine_bones: THREE.Mesh;
        spine_disks: THREE.Mesh;
    };
    materials: {
        ["skeletal_material.002"]: THREE.MeshStandardMaterial;
    };
};

export function TorsoSkeliton(props: JSX.IntrinsicElements["group"]) {
    const { nodes, materials } = useGLTF("/torso_skeliton.glb") as GLTFResult;
    return (
        <group {...props} dispose={null}>
            <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
                <mesh
                    geometry={nodes.coccyx.geometry}
                    material={materials["skeletal_material.002"]}
                    position={[-0.18, 12.52, 48.57]}
                />
                <mesh
                    geometry={nodes.costillas.geometry}
                    material={materials["skeletal_material.002"]}
                    position={[-75.85, -26.75, -135.03]}
                />
                <mesh
                    geometry={nodes.externon.geometry}
                    material={materials["skeletal_material.002"]}
                    position={[0.17, 7.93, -124.49]}
                />
                <mesh
                    geometry={nodes.omoplato.geometry}
                    material={materials["skeletal_material.002"]}
                    position={[0.17, -45.45, -158.83]}
                />
                <mesh
                    geometry={nodes.pelvic_girdle.geometry}
                    material={materials["skeletal_material.002"]}
                    position={[-0.34, 1.14, -0.35]}
                />
                <mesh
                    geometry={nodes.spine_bones.geometry}
                    material={materials["skeletal_material.002"]}
                    position={[0.16, -41.41, -107.65]}
                />
                <mesh
                    geometry={nodes.spine_disks.geometry}
                    material={materials["skeletal_material.002"]}
                    position={[0.51, -32.85, -119.97]}
                />
            </group>
        </group>
    );
}

useGLTF.preload("/torso_skeliton.glb");
