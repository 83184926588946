//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useNavigate } from "react-router-dom";
// Material ui Imports
import { Grid, Typography, Button, Tooltip } from "@mui/material";
// Custom components imports (and hooks and helpers)
import BugFixImage from "../../assets/images/BugFixImage";
import { useEffect } from "react";
import { useAppDispatch } from "../../store/hooks";
import { setCurrentPage } from "../../store/slices/current.slice";
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
// Optional variables towards the bottom

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Error Page, to display on breaking errors and allows home navigation
export default function Error() {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setCurrentPage("error"));     
    }, []);

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Displays return button and error message
    return (
        <Grid
            container
            style={{ height: "100vh" }}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
        >
            {/*-------------------------------------------------------------------------------------*/}
            {/* Return to home button */}
            <Tooltip title={"Let's go!"}>
                <Button
                    variant={"text"}
                    onClick={() => {
                        navigate("/");
                    }}
                    style={{ position: "absolute", top: "15px", left: "15px" }}
                >
                    {"< Take me home"}
                </Button>
            </Tooltip>

            {/*-------------------------------------------------------------------------------------*/}
            {/* Error message */}
            <Typography variant={"h4"} color={"primary"}>
                Uh oh! That wasn't supposed to happen...
            </Typography>
            <Typography variant={"body1"} style={{ fontWeight: 200, marginBottom: 20 }} color={"primary"}>
                If this issue persists please reach out to the team at Virtus Tech.
            </Typography>

            {/*-------------------------------------------------------------------------------------*/}
            {/* Image */}
            <BugFixImage style={{ width: "50vw", minWidth: "300px", maxHeight: 500 }} color={"#0fffff"} />
        </Grid>
    );
}
