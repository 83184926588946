//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
import { Grid, Tooltip } from "@mui/material";
import { IHotspot, IUserInterface } from "../../models/hotspot.models";
import { VTButton, VTRichTextEditor, VTTextField, VTTypography } from "@virtus-tech-repository/virtus-tech-repository";

import LogoutIcon from "@mui/icons-material/Logout";
import { useEffect, useRef, useState } from "react";
import { AddRounded, DeleteRounded, SaveRounded, CancelRounded } from "@mui/icons-material";
import { setHotspotActions, setSceneActions } from "../../store/slices/tracking.slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
// Custom components imports (and hooks and helpers)
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    currentInterface: IHotspot;
    setCurrentInterface: (currentInteface: IHotspot | undefined) => void;
    handleDeleteHotspotLocation: (hotspotId: string) => void;
    handleUpdateInterface: (hotspotId: string, newInterface: IHotspot) => void;
    handleProceedVideo?: any;
    handleChangeScene?: (sceneId: string, delay: number | undefined) => void;
    videoCurrentState?: string;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
export default function VTUserInterface({
    currentInterface,
    setCurrentInterface,
    handleDeleteHotspotLocation,
    handleUpdateInterface,
    handleProceedVideo,
    handleChangeScene,
    videoCurrentState,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [localUserInterface, setLocalUserInterface] = useState<IHotspot>(currentInterface);
    const [userInterfaceIndex, setUserInterfaceIndex] = useState<number>(0);

    const [localUserInterfaceTitle, setLocalUserInterfaceTitle] = useState<string>("");
    const [localUserInterfaceTitleEdit, setLocalUserInterfaceTitleEdit] = useState<boolean>(false);

    const [localSectionTitle, setLocalSectionTitle] = useState<string>("");
    const [localSectionTitleEdit, setLocalSectionTitleEdit] = useState<boolean>(false);

    const [localSectionDescription, setLocalSectionDescription] = useState<string | undefined>(undefined);
    const [localSectionDescriptionEdit, setLocalSectionDescriptionEdit] = useState<boolean>(false);

    const [localSectionFeedback, setLocalSectionFeedback] = useState<string | undefined>(undefined);
    const [localSectionFeedbackEdit, setLocalSectionFeedbackEdit] = useState<boolean>(false);

    const [showModelAnswer, setShowModelAnswer] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const { currentQuestion } = useAppSelector((state) => state.currentReducer);

    const [userInput, setUserInput] = useState<string>("");

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    useEffect(() => {
        setLocalSectionTitle(
            //@ts-ignore
            localUserInterface.contents.preClickContent.userInterface[userInterfaceIndex]!.sectionTitle!,
        );
        setLocalSectionDescription(
            //@ts-ignore
            localUserInterface.contents.preClickContent.userInterface[userInterfaceIndex]!.sectionDescription!,
        );
        setLocalSectionFeedback(
            //@ts-ignore
            localUserInterface.contents.preClickContent.userInterface[userInterfaceIndex]!.sectionFeedback!,
        );
    }, [userInterfaceIndex]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    // Add new Section to interface
    function handlesAddSectionToLocalInterface() {
        setLocalUserInterface({
            ...localUserInterface,
            contents: {
                ...localUserInterface.contents,
                preClickContent: {
                    ...localUserInterface.contents.preClickContent,
                    userInterface: [
                        ...localUserInterface.contents.preClickContent.userInterface!,
                        {
                            sectionTitle: `New Section ${localUserInterface.contents.preClickContent.userInterface
                                ?.length!}`,
                        },
                    ],
                },
            },
        });
    }

    // Delete Section from interface
    function handlesDeleteSectionToLocalInterface() {
        setLocalUserInterface({
            ...localUserInterface,
            contents: {
                ...localUserInterface.contents,
                preClickContent: {
                    ...localUserInterface.contents.preClickContent,
                    userInterface: localUserInterface.contents.preClickContent.userInterface!.filter(
                        (section: IUserInterface, index: number) => index !== userInterfaceIndex,
                    ),
                },
            },
        });
    }

    // Update interfaces overall title
    function handleUpdateInterfaceTitle(newTitle: string) {
        setLocalUserInterface({
            ...localUserInterface,
            contents: {
                ...localUserInterface.contents,
                preClickContent: {
                    ...localUserInterface.contents.preClickContent,
                    title: newTitle,
                },
            },
        });
    }

    // Update the current section of the interface
    function handleUpdateSection(value: string, newContent: string | undefined) {
        setLocalUserInterface({
            ...localUserInterface,
            contents: {
                ...localUserInterface.contents,
                preClickContent: {
                    ...localUserInterface.contents.preClickContent,
                    userInterface: localUserInterface.contents.preClickContent.userInterface!.map(
                        (userSection: IUserInterface, index: number) =>
                            index === userInterfaceIndex
                                ? {
                                      ...userSection,
                                      [value]: newContent,
                                  }
                                : userSection,
                    ),
                },
            },
        });
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Show creator page tabs options
    return (
        <Grid
            container
            sx={{
                position: "absolute",
                right: 0,
                width: "400px",
                height: "100%",
                backgroundColor: "#3a3b3d",
                zIndex: 10,
                padding: "15px",
            }}
            justifyContent={"center"}
            alignContent={"space-between"}
        >
            {/*------------------------------------------------------------------------------------*/}
            {/* Interface Title Section */}
            {/*------------------------------------------------------------------------------------*/}
            {/* Interface Title */}
            <Grid item container justifyContent={"center"} alignContent={"center"}>
                {/*--------------------------------------------------------------------------------*/}
                {/* If editing interface title show text field and data management options */}

                <>
                    {/*----------------------------------------------------------------------------*/}
                    {/* If not currently editing show normal title */}
                    <Grid item sx={{ marginTop: "8px" }}>
                        {localUserInterface.contents.preClickContent.title}
                    </Grid>
                </>
            </Grid>

            {/*----------------------------------------------------------------------------------------*/}
            {/* Interface Timeline section */}
            {/*----------------------------------------------------------------------------------------*/}
            {/* Interface Timeline */}
            <Grid item xs={12} container justifyContent={"center"}>
                <Grid
                    item
                    container
                    justifyContent={"space-between"}
                    sx={{
                        width:
                            localUserInterface.contents.preClickContent.userInterface?.length === 1
                                ? "0px"
                                : `${localUserInterface.contents.preClickContent.userInterface?.length! * 60 + 60}px `,
                        backgroundColor: "#fff",
                        marginLeft: "17px",
                        flexWrap: "wrap",
                        height: "2px",
                    }}
                >
                    {/*--------------------------------------------------------------------------------*/}
                    {/* Map interface sections to timeline for navigation */}
                    {localUserInterface.contents.preClickContent.userInterface?.map(
                        (section: IUserInterface, index: number) => (
                            <Tooltip title={section.sectionTitle}>
                                <Grid item>
                                    <VTButton
                                        type={"icon"}
                                        sx={{
                                            padding: 0,
                                            marginTop: userInterfaceIndex === index ? "-21px" : "-19px",
                                            marginLeft: userInterfaceIndex === index ? "-21px" : "-19px",
                                            width: userInterfaceIndex === index ? "45px" : "40px",
                                            height: userInterfaceIndex === index ? "45px" : "40px",
                                            background:
                                                userInterfaceIndex === index
                                                    ? "linear-gradient(to bottom right, #0ab5a7,#8e25fa)"
                                                    : "#7315ce",
                                            transition: ".5s",
                                            ":hover": {
                                                boxShadow: "0 0 25px #2ad5c7",
                                            },
                                        }}
                                        onClick={() => {
                                            setLocalSectionTitleEdit(false);
                                            setLocalSectionDescriptionEdit(false);
                                            setLocalSectionFeedbackEdit(false);
                                            setUserInterfaceIndex(index);
                                        }}
                                    >
                                        {index}
                                    </VTButton>
                                </Grid>
                            </Tooltip>
                        ),
                    )}
                </Grid>
            </Grid>

            {/*----------------------------------------------------------------------------------------*/}
            {/* Section Title area */}
            {/*----------------------------------------------------------------------------------------*/}
            <Grid container item xs={12} sx={{ height: "calc(100% - 200px)", overflowY: "auto" }}>
                <Grid item container xs={12}>
                    {/*--------------------------------------------------------------------------------*/}
                    {/* If editing section title */}

                    <Grid item sx={{ marginTop: "8px" }} onClick={() => setLocalSectionTitleEdit(true)}>
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Display section title */}
                        {localUserInterface.contents.preClickContent.userInterface![userInterfaceIndex].sectionTitle}
                    </Grid>
                </Grid>

                {/*----------------------------------------------------------------------------------------*/}
                {/* Section Question/description area */}
                {/*----------------------------------------------------------------------------------------*/}
                <Grid item xs={12}>
                    {/* Allow user to set as undefined and to force re-render to update rich text editor */}

                    {localSectionDescription && (
                        <>
                            <div
                                style={{ width: "100%" }}
                                onClick={() => {
                                    if (localSectionFeedbackEdit) {
                                        handleUpdateSection("sectionFeedback", localSectionFeedback);
                                        setLocalSectionFeedbackEdit(false);
                                    }
                                    setLocalSectionDescriptionEdit(true);
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: localSectionDescription,
                                }}
                            />
                        </>
                    )}
                </Grid>

                <Grid item xs={12} justifyContent={"center"} container>
                    <VTTextField
                        multiline
                        rows={5}
                        sx={{ width: "90%" }}
                        value={userInput}
                        onChange={(e) => setUserInput(e)}
                    />
                </Grid>

                {/*----------------------------------------------------------------------------------------*/}
                {/* Section Question/description area */}
                {/*----------------------------------------------------------------------------------------*/}
                <Grid item xs={12}>
                    {/* Allow user to set as undefined and to force re-render to update rich text editor */}
                    {localSectionFeedback !== undefined && (
                        <Grid
                            item
                            container
                            sx={{ height: "100%", cursor: "pointer" }}
                            onClick={() => setShowModelAnswer(true)}
                        >
                            <>
                                {showModelAnswer ? (
                                    <div
                                        style={{ width: "100%" }}
                                        onClick={() => {
                                            if (localSectionDescriptionEdit) {
                                                handleUpdateSection("sectionDescription", localSectionDescription);
                                                setLocalSectionDescriptionEdit(false);
                                            }
                                            setLocalSectionFeedbackEdit(true);
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: localSectionFeedback,
                                        }}
                                    />
                                ) : (
                                    <VTTypography>Model answer</VTTypography>
                                )}
                            </>
                        </Grid>
                    )}
                </Grid>
            </Grid>

            {/*------------------------------------------------------------------------------------------*/}
            {/* Closes the interface panel */}
            <VTButton
                sx={{
                    width: "10px",
                    height: "40px",
                    borderRadius: "15px 0px 0px 15px",
                    backgroundColor: "#a9a9a9",
                    ":hover": {
                        cursor: "pointer",
                        backgroundColor: "#888888",
                        boxShadow: "0 0 25px #2ad5c7",
                    },
                    marginBlock: "15px",
                    position: "absolute",
                    left: "-64px",
                    top: "42%",
                }}
                onClick={() => setCurrentInterface(undefined)}
            >
                <LogoutIcon />
            </VTButton>

            <Grid item container xs={12} justifyContent={"center"} sx={{ marginTop: "-35px" }}>
                {/*------------------------------------------------------------------------------------------*/}
                {/* Resets the data with the sever */}
                <Grid item>
                    <VTButton
                        sx={{ width: "150px", marginRight: "15px" }}
                        onClick={() => {
                            setCurrentInterface(undefined);
                        }}
                        color={"secondary"}
                    >
                        Cancel
                    </VTButton>
                </Grid>
                {/*------------------------------------------------------------------------------------------*/}
                {/* sets data on the server */}
                <Grid item>
                    {localUserInterface.contents.preClickContent.userInterface!.length - 1 === userInterfaceIndex ? (
                        <VTButton
                            sx={{ width: "150px" }}
                            onClick={() => {
                                dispatch(
                                    setHotspotActions({
                                        hotspotId: localUserInterface.id,
                                        action: "InterfaceSubmitted",
                                        date: new Date(),
                                        questionId: videoCurrentState === "Question" ? currentQuestion.id : undefined,
                                        data: userInput,
                                    }),
                                );
                                if (localUserInterface.actions?.movement?.sceneId && handleChangeScene) {
                                    handleChangeScene(localUserInterface.actions?.movement?.sceneId, 0);
                                } else if (localUserInterface.actions?.appFeedback?.proceed && handleProceedVideo) {
                                    handleProceedVideo(0);
                                }
                                setCurrentInterface(undefined);
                            }}
                        >
                            Submit
                        </VTButton>
                    ) : (
                        <VTButton
                            sx={{ width: "150px" }}
                            onClick={() => {
                                setUserInterfaceIndex(userInterfaceIndex + 1);
                            }}
                        >
                            Next
                        </VTButton>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}
function setLocalSectionTitle(arg0: string) {
    throw new Error("Function not implemented.");
}
