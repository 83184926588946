import { useVideoTexture } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { RayGrab } from "@react-three/xr";
import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
export default function Video({ url }: any) {
    const [pause, setPause] = useState(false);

    const props: any = {
        unsuspend: "canplay",
        muted: false,
        loop: true,
        start: pause,
    };

    const texture = useVideoTexture(url);
    const aspect = 1920 / 1080;

    const videoTexture = useVideoTexture(url, props);

    const playVideo = () => {
        setPause(true);
    };

    return (
        <RayGrab>
            <mesh onAfterRender={playVideo}>
                <planeGeometry args={[1 * aspect, 1, 1, 1]} />
                <meshBasicMaterial map={texture} side={THREE.DoubleSide} toneMapped={false} />
            </mesh>
        </RayGrab>
    );
}
