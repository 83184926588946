/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 .\torso.glb -t
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
    nodes: {
        Scene293: THREE.Mesh;
        Scene293_1: THREE.Mesh;
    };
    materials: {
        ["base_man_material.001"]: THREE.MeshStandardMaterial;
    };
};

export function Torso(props: JSX.IntrinsicElements["group"]) {
    const { nodes, materials } = useGLTF("/torso.glb") as GLTFResult;
    return (
        <group {...props} dispose={null}>
            <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
                <mesh geometry={nodes.Scene293.geometry} material={materials["base_man_material.001"]} />
                <mesh geometry={nodes.Scene293_1.geometry} material={materials["base_man_material.001"]} />
            </group>
        </group>
    );
}

useGLTF.preload("/torso.glb");
