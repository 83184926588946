import HomeContentWrapper from "../layouts/HomeContentWrapper";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useEffect } from "react";
import { setCurrentPage } from "../../store/slices/current.slice";
import { CircularProgress, Grid, Typography } from "@mui/material";

// Only display layouts in pages
export default function Home() {
    const dispatch = useAppDispatch();
    const { id: userId } = useAppSelector((state) => state.userReducer);

    useEffect(() => {
        dispatch(setCurrentPage("home"));
    }, []);

    return (
        <>
            {/* Page content */}
            {userId && userId === "" ? (
                <Grid container sx={{ marginTop: "100px" }} justifyContent={"center"}>
                    <CircularProgress />
                </Grid>
            ) : (
                <HomeContentWrapper userId={userId} />
            )}
        </>
    );
}
