//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
// Custom components imports (and hooks and helpers)
import { v4 as uuid } from "uuid";
import { EMedia } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { IScenarioOverview, IScene } from "../models/scenario.models";
import { useAppSelector } from "../store/hooks";
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Renders the different hotspots
export function blankScenarioOverview(id: string): IScenarioOverview {
    //-------------------------------------------------------------------------------------------------
    // ## Declares ##
    const newScenarioOverview: IScenarioOverview = {
        id: uuid(),
        title: "",
        description: "",
        previewImage: "",
        admins: [],
        viewers: [],
        tags: [],
        locked: false,
        owner: id,
        publishedVersion: "latests",
        date: new Date(),
    };

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    return newScenarioOverview;
}

export function blankScene(newSrc: string, type: EMedia, preview?: string, id?: string): IScene {
    //-------------------------------------------------------------------------------------------------
    // ## Declares ##
    const newScene: IScene = {
        id: id ? id : uuid(),
        name: "New Scene",
        src: newSrc,
        mediaType: type as EMedia,
        hotspots: [],
        context: {},
        startLocation: [0, 0, 0],
        previewImage: preview,
    };

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    return newScene;
}
