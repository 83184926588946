//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports

// Material ui Imports
import { Grid, Skeleton, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router";

// Custom components imports (and hooks and helpers)
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { VTButton, VTCard, VTTypography } from "@virtus-tech-repository/virtus-tech-repository";
import VTScenarioCard from "../components/VTScenarioCard";

import { IScenarioOverview } from "../../models/scenario.models";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { blankScenarioOverview } from "../../helpers/scenarioDataGenerator";
import NoScenarios from "../../assets/images/NoScenarios.svg";
import { setCurrentSidePanel } from "../../store/slices/current.slice";
import { useDispatch } from "react-redux";
import { ContentCopyRounded, DeleteRounded, EditRounded } from "@mui/icons-material";
import { MouseEvent, MutableRefObject, useEffect, useRef } from "react";
import { useAppSelector } from "../../store/hooks";
import { useUpdateScenarioOverviewMutation } from "../../services/scenario.service";
import { useGetMuiBreakPointLabel } from "../../hooks/useGetBreakpointLabel";
// import { useGetBreakPointLabel } from "../../hooks/useGetBreakpointLabel";

// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
// Optional variables towards the bottom
interface IProps {
    data: IScenarioOverview[] | undefined;
    isLoading: boolean;
    userAttemptedScenarios: string[] | undefined;
    leftDrawWidth: number;
    searchValue: string;
    setScenarioDialog: (scenarioDialog: any) => void;
    handleDeleteScenario: (id: string, accessId: string[]) => void;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
export default function HomeCardGridContainer({
    data,
    isLoading,
    leftDrawWidth,
    userAttemptedScenarios,
    searchValue,
    setScenarioDialog,
    handleDeleteScenario,
}: IProps) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();

    const isAboveMdScreenWidth = useMediaQuery(theme.breakpoints.up("md"));

    const breakPointLabel = useGetMuiBreakPointLabel();

    function shouldNotAddPaddingRightToGridItem(gridItemNumber: number) {
        if (gridItemNumber % 4 === 0 && breakPointLabel === "lg") {
            return true;
        } else if (gridItemNumber % 3 === 0 && breakPointLabel === "md") {
            return true;
        } else if (gridItemNumber % 2 === 0 && breakPointLabel === "sm") {
            return true;
        } else if (gridItemNumber % 1 === 0 && breakPointLabel === "xs") {
            return true;
        } else {
            return false;
        }
    }

    const [updateScenarioOverview] = useUpdateScenarioOverviewMutation();

    const { id } = useAppSelector((state) => state.userReducer);

    const vtCardRef = useRef<HTMLDivElement>(null);

    return (
        <Grid container xs={12} sx={{}}>
            <Grid
                // item
                container
                // xs={12}
                spacing={3}
                rowSpacing={3}
                justifyContent={"space-evenly"}
                sx={{
                    // background: 'red',
                    marginTop: 1,
                    minHeight: 200,
                    marginLeft: leftDrawWidth / 8 + (!isLoading ? 2 : 5),
                    marginRight: 5,
                    marginBottom: "30px",
                    // display: "flex",
                    transition: "0.5s",
                    // gap: "10px"
                }}
                // justifyContent={"space-evenly"}
            >
                {!isLoading ? (
                    <>
                        {data && data.length === 0 ? (
                            <Grid item xs={12} textAlign={"center"}>
                                <img src={NoScenarios} />
                                <VTTypography sx={{ color: "grey", padding: "10px" }}> No scenarios found</VTTypography>
                            </Grid>
                        ) : (
                            data
                                ?.filter(
                                    (element: any) =>
                                        element.title.toLowerCase().includes(searchValue.toLowerCase()) ||
                                        element.description.toLowerCase().includes(searchValue.toLowerCase()),
                                )
                                .sort((a, b) => {
                                    // TODO: this can be removed once all scenario have a date
                                    const dateA = a.date ? a.date : 0;
                                    const dateB = b.date ? b.date : 0;

                                    return new Date(dateB).valueOf() - new Date(dateA).valueOf();
                                })
                                .map((scenarioOverview: IScenarioOverview, i: number) => {
                                    let menuOptions = [
                                        {
                                            component: (
                                                <VTButton
                                                    type={"list"}
                                                    startIcon={<EditRounded />}
                                                    onClick={() => {
                                                        setScenarioDialog(scenarioOverview);
                                                        dispatch(
                                                            setCurrentSidePanel({
                                                                currentTab: undefined,
                                                                width: 0,
                                                                option: undefined,
                                                            }),
                                                        );
                                                        // setMenuOpen(false);
                                                    }}
                                                >
                                                    Edit
                                                </VTButton>
                                            ),
                                        },
                                    ];

                                    if (scenarioOverview.owner === id) {
                                        menuOptions.push({
                                            component: (
                                                <VTButton
                                                    type={"list"}
                                                    startIcon={<DeleteRounded />}
                                                    onClick={() => {
                                                        handleDeleteScenario(
                                                            scenarioOverview.id,
                                                            scenarioOverview.admins,
                                                        );
                                                    }}
                                                >
                                                    Delete
                                                </VTButton>
                                            ),
                                        });
                                    }

                                    return (
                                        <Grid
                                            lg={3}
                                            md={4}
                                            sm={6}
                                            xs={12}
                                            // flexWrap={}
                                            key={scenarioOverview.id}
                                            item
                                            sx={
                                                {
                                                    // marginBottom: "20px",
                                                    // paddingRight: shouldNotAddPaddingRightToGridItem(i + 1) ? "none" : "20px",
                                                    // height: "100%"
                                                }
                                            }
                                        >
                                            <VTCard
                                                // ref={vtCardRef}
                                                scenarioIsComplete={
                                                    userAttemptedScenarios &&
                                                    userAttemptedScenarios.includes(scenarioOverview.id)
                                                }
                                                sx={{ width: "100%", maxWidth: "none" }}
                                                title={scenarioOverview.title}
                                                titleCutOffPoint={isAboveMdScreenWidth ? 17 : 13}
                                                textCutOffPoint={25}
                                                text={scenarioOverview.description ? scenarioOverview.description : ""}
                                                onWholeCardClicked={() => {
                                                    navigate(`/viewer/${scenarioOverview.id}`);
                                                }}
                                                mediaType={{
                                                    imageUrl: scenarioOverview.previewImage,
                                                }}
                                            />
                                        </Grid>
                                    );
                                })
                        )}
                    </>
                ) : (
                    <Grid container item justifyContent={"space-evenly"} spacing={3} sx={{ marginBottom: "30px" }}>
                        {[1, 2, 3, 4].map((_) => (
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <VTCard title="" loading={true} />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}
