import { Breakpoint, useMediaQuery, useTheme } from "@mui/material";

/**
xs, extra-small: 0px
sm, small: 600px
md, medium: 900px
lg, large: 1200px
xl, extra-large: 1536px
 */
export function useGetMuiBreakPointLabel(): Breakpoint {
    const theme = useTheme();

    const isAboveLgScreenWidth = useMediaQuery(theme.breakpoints.up("lg"));

    const isAboveMdScreenWidth = useMediaQuery(theme.breakpoints.up("md"));

    const isAboveXlScreenWidth = useMediaQuery(theme.breakpoints.up("xl"));

    const isAboveSmScreenWidth = useMediaQuery(theme.breakpoints.up("sm"));

    function getScreenWidthBreakPointLabel(): Breakpoint {
        if (isAboveXlScreenWidth) {
            return "xl";
        } else if (isAboveLgScreenWidth) {
            return "lg";
        } else if (isAboveMdScreenWidth) {
            return "md";
        } else if (isAboveSmScreenWidth) {
            return "sm";
        } else {
            return "xs";
        }
    }

    return getScreenWidthBreakPointLabel();
}
