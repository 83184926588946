import {
    VTButton,
    VTCanvas,
    VTDialog,
    VTRichTextEditor,
    VTTextField,
    VTTypography,
} from "@virtus-tech-repository/virtus-tech-repository";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import currentSlice, {
    setCurrentHotspot,
    setCurrentSidePanel,
    setEditHotspotDialogIsCurrentlyOpen,
    setEditHotspotDialogMediaTransfer,
    setEditHotspotDialogTabCurrentlyOpen,
} from "../../store/slices/current.slice";
import { Grid, MenuItem, Select, Slider, SxProps, TextField, Typography, useTheme } from "@mui/material";
import VTHotpsot from "./VTHotspot";
import { useEffect, useRef, useState } from "react";
import { InfoRounded, KeyboardDoubleArrowUpRounded, RadioButtonCheckedRounded } from "@mui/icons-material";
import { EHotspotType, IHotspot } from "../../models/hotspot.models";
import { IScenario, IScene } from "../../models/scenario.models";
import { Params, useParams } from "react-router-dom";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { CompactPicker, SketchPicker } from "react-color";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { EditHotSpotDialogTab } from "../../models/current.models";
import VTImageDropper from "./VTImageDropper";
import VTVideoDropper from "./VTVideoDropper";
import VTAudioDropper from "./VTAudioDropper";
import { EDragContentOptions } from "../../models/hotspotDrag.models";
import { getDurationOfAudioInSeconds } from "../../utils/getAudioClipDuration";

export default function VTHotspotEditDialog({
    scenario,
    updateScenario,
    sx,
    videoNextQuestion,
    videoCurrentTime,
    videoCurrentState,
}: {
    sx: SxProps;
    scenario: any;
    updateScenario: any;
    videoNextQuestion: any;
    videoCurrentTime: number;
    videoCurrentState: string | undefined;
}) {
    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();
    const theme = useTheme();

    // useEffect(() => {
    //   let tid = window.setInterval(() => {
    //   }, 500);
    //   return () => {
    //     window.clearInterval(tid);
    //   }
    // }, []);

    const dispatch = useAppDispatch();
    const { currentHotspot, currentQuestion, currentSidePanel, editHotspotDialogMediaTransfer, currentlyDragging } =
        useAppSelector((state) => state.currentReducer);

    const currentlyDraggingObj = {
        src: currentlyDragging?.src ? currentlyDragging.src : "",
        preview: currentlyDragging?.preview ? currentlyDragging.preview : "",
        mediaType: "images",
        // mediaType: (currentlyDragging?.mediaType ? currentlyDragging.mediaType : "") as EDragContentOptions
    };

    const openDialog = currentHotspot.currentHotspot !== undefined && currentHotspot.open;

    const [currentEditTab, setCurrentEditTab] = useState<EditHotSpotDialogTab>("content");
    const [currentPrePostTab, setCurrentPrePostTab] = useState<"pre" | "post">("pre");

    const [preClickTitle, setPreClickTitle] = useState<string>("");
    const [postClickTitle, setPostClickTitle] = useState<string>("");
    const [preClickIcon, setPreClickIcon] = useState<string>("");
    const [postClickIcon, setPostClickIcon] = useState<string>("");
    const [preClickContent, setPreClickContent] = useState<string>("");
    const [postClickContent, setPostClickContent] = useState<string>("");

    const [preClickBackgroundColour, setPreClickBackgroundColour] = useState<string>("");
    const [postClickBackgroundColour, setPostClickBackgroundColour] = useState<string>("");
    const [preClickTextColour, setPreClickTextColour] = useState<string>("");
    const [postClickTextColour, setPostClickTextColour] = useState<string>("");
    const [preClickScale, setPreClickScale] = useState<number>(1);
    const [postClickScale, setPostClickScale] = useState<number>(1);

    const [actionSceneId, setActionSceneId] = useState<string>("");
    const [actionSceneDelay, setActionSceneDelay] = useState<number>(0);
    const [actionFeedbackImage, setActionFeedbackImage] = useState<string>("");
    const [actionFeedbackVideo, setActionFeedbackVideo] = useState<string>("");
    const [actionFeedbackAudio, setActionFeedbackAudio] = useState<string>("");
    const [actionFeedbackInput, setActionFeedbackInput] = useState<string>("");
    const [actionNavigate, setActionNavigate] = useState<string>("");
    const [actionCloseable, setActionCloseable] = useState<boolean>(false);
    const [actionProceed, setActionProceed] = useState<boolean>(false);
    const [actionFeedbackDelay, setActionFeedbackDelay] = useState<number>(0);

    useEffect(() => {
        if (openDialog) {
            if (currentSidePanel.currentTab) {
                dispatch(
                    setCurrentSidePanel({
                        currentTab: "Images",
                        width: window.innerWidth / 4 > 400 ? 400 : 200,
                        option: undefined,
                    }),
                );
            }

            dispatch(setEditHotspotDialogIsCurrentlyOpen(true));
        } else {
            // TODO: investigate why this is fixing it (race condiiton with redux action dispatches?)
            dispatch(setEditHotspotDialogIsCurrentlyOpen(false));
            dispatch(setEditHotspotDialogTabCurrentlyOpen(undefined)); // as edit hotspot dialog is closed, no tab within it can be open now
            dispatch(
                setCurrentSidePanel({
                    currentTab: undefined,
                    width: 0,
                    option: undefined,
                }),
            );
        }
    }, [openDialog]);

    useEffect(() => {
        dispatch(setEditHotspotDialogTabCurrentlyOpen(currentEditTab));
    }, [currentEditTab, openDialog]);

    function handleAudioDropped(audioItem: string) {
        setActionFeedbackAudio(audioItem);
        if (audioItem !== "") {
            const storedAudio = new Audio(audioItem);
            storedAudio.addEventListener("loadedmetadata", function () {
                if (storedAudio.duration) {
                    setActionFeedbackDelay(Math.ceil(storedAudio.duration));
                    setActionSceneDelay(Math.ceil(storedAudio.duration));
                }
            });
        }
    }

    useEffect(() => {
        if (editHotspotDialogMediaTransfer !== undefined) {
            if (currentEditTab === "actions") {
                if (editHotspotDialogMediaTransfer.type === EDragContentOptions.IMAGE) {
                    setActionFeedbackImage(editHotspotDialogMediaTransfer.src);
                } else if (editHotspotDialogMediaTransfer.type === EDragContentOptions.VIDEO) {
                    setActionFeedbackVideo(editHotspotDialogMediaTransfer.src);
                } else if (editHotspotDialogMediaTransfer.type === EDragContentOptions.AUDIO) {
                    handleAudioDropped(editHotspotDialogMediaTransfer.src);
                }
            } else {
                //#TODO; add on to the end of the content for pre and post when double clicking on the side panel
            }
            dispatch(setEditHotspotDialogMediaTransfer(undefined));
        }
    }, [editHotspotDialogMediaTransfer]);

    useEffect(() => {
        if (currentHotspot && currentHotspot.currentHotspot) {
            setPreClickTitle(
                currentHotspot?.currentHotspot.contents.preClickContent.title
                    ? currentHotspot?.currentHotspot.contents.preClickContent.title
                    : "",
            );
            setPostClickTitle(
                currentHotspot?.currentHotspot.contents.postClickContent?.title
                    ? currentHotspot?.currentHotspot.contents.postClickContent.title
                    : "",
            );
            setPreClickIcon(
                currentHotspot?.currentHotspot.contents.preClickContent.icon
                    ? currentHotspot?.currentHotspot.contents.preClickContent.icon
                    : "",
            );
            setPostClickIcon(
                currentHotspot?.currentHotspot.contents.postClickContent?.icon
                    ? currentHotspot?.currentHotspot.contents.postClickContent.icon
                    : "",
            );
            setPreClickContent(
                currentHotspot?.currentHotspot.contents.preClickContent?.contentData
                    ? currentHotspot?.currentHotspot.contents.preClickContent.contentData
                    : "",
            );
            setPostClickContent(
                currentHotspot?.currentHotspot.contents.postClickContent?.contentData
                    ? currentHotspot?.currentHotspot.contents.postClickContent.contentData
                    : "",
            );

            setPreClickBackgroundColour(
                currentHotspot?.currentHotspot.styles?.preClickStyles?.colours?.background
                    ? currentHotspot?.currentHotspot.styles?.preClickStyles?.colours?.background
                    : "",
            );
            setPostClickBackgroundColour(
                currentHotspot?.currentHotspot.styles?.postClickStyles?.colours?.background
                    ? currentHotspot?.currentHotspot.styles?.postClickStyles?.colours?.background
                    : "",
            );
            setPreClickTextColour(
                currentHotspot?.currentHotspot.styles?.preClickStyles?.colours?.text
                    ? currentHotspot?.currentHotspot.styles?.preClickStyles?.colours?.text
                    : "",
            );
            setPostClickTextColour(
                currentHotspot?.currentHotspot.styles?.postClickStyles?.colours?.text
                    ? currentHotspot?.currentHotspot.styles?.postClickStyles?.colours?.text
                    : "",
            );
            setPreClickScale(
                currentHotspot?.currentHotspot.styles?.preClickStyles?.scale
                    ? currentHotspot?.currentHotspot.styles?.preClickStyles?.scale
                    : 1,
            );
            setPostClickScale(
                currentHotspot?.currentHotspot.styles?.postClickStyles?.scale
                    ? currentHotspot?.currentHotspot.styles?.postClickStyles?.scale
                    : 1,
            );

            setActionSceneId(
                currentHotspot?.currentHotspot.actions?.movement?.sceneId
                    ? currentHotspot?.currentHotspot.actions?.movement?.sceneId
                    : "",
            );
            setActionSceneDelay(
                currentHotspot?.currentHotspot.actions?.movement?.delay
                    ? currentHotspot?.currentHotspot.actions?.movement?.delay
                    : 0,
            );
            setActionFeedbackImage(
                currentHotspot?.currentHotspot.actions?.appFeedback?.image
                    ? currentHotspot?.currentHotspot.actions?.appFeedback?.image
                    : "",
            );
            setActionFeedbackVideo(
                currentHotspot?.currentHotspot.actions?.appFeedback?.video
                    ? currentHotspot?.currentHotspot.actions?.appFeedback?.video
                    : "",
            );
            setActionFeedbackInput(
                currentHotspot?.currentHotspot.actions?.appFeedback?.input
                    ? currentHotspot?.currentHotspot.actions?.appFeedback?.input
                    : "",
            );
            setActionNavigate(
                currentHotspot?.currentHotspot.actions?.navigate
                    ? currentHotspot?.currentHotspot.actions?.navigate
                    : "",
            );

            setActionFeedbackAudio(
                currentHotspot?.currentHotspot.actions?.appFeedback?.audio
                    ? currentHotspot?.currentHotspot.actions?.appFeedback?.audio
                    : "",
            );
            setActionCloseable(
                currentHotspot?.currentHotspot.actions?.closeable
                    ? currentHotspot?.currentHotspot.actions?.closeable
                    : false,
            );
            setActionProceed(
                currentHotspot?.currentHotspot.actions?.appFeedback?.proceed
                    ? currentHotspot?.currentHotspot.actions?.appFeedback.proceed
                    : false,
            );
            setActionFeedbackDelay(
                currentHotspot?.currentHotspot.actions?.appFeedback?.delay
                    ? currentHotspot?.currentHotspot.actions?.appFeedback.delay
                    : 0,
            );
        }
    }, [currentHotspot]);

    const [postRichTextEditorInitialContent, setPostRichTextEditorInitialContent] = useState<string>("");
    const [preRichTextEditorInitialContent, setPreRichTextEditorInitialContent] = useState<string>("");

    // const preClickRichTextEditorContentRef = useRef<string>(preClickContent);
    // const postClickRichTextEditorContentRef = useRef<string>(postClickContent);

    useEffect(() => {
        setPostRichTextEditorInitialContent(postClickContent);
        setPreRichTextEditorInitialContent(preClickContent);
    }, [currentHotspot, currentPrePostTab]);

    function createNewHotspot() {
        if (currentHotspot.currentHotspot) {
            return {
                id: currentHotspot.currentHotspot.id,
                location: currentHotspot?.currentHotspot.location,
                type: currentHotspot?.currentHotspot.type,
                styles: {
                    preClickStyles: {
                        colours: {
                            background: preClickBackgroundColour !== "" ? preClickBackgroundColour : undefined,
                            text: preClickTextColour !== "" ? preClickTextColour : undefined,
                        },
                        scale: preClickScale !== 1 ? preClickScale : undefined,
                    },
                    postClickStyles: {
                        colours: {
                            background: postClickBackgroundColour !== "" ? postClickBackgroundColour : undefined,
                            text: postClickTextColour !== "" ? postClickTextColour : undefined,
                        },
                        scale: postClickScale !== 1 ? postClickScale : undefined,
                    },
                },
                contents: {
                    preClickContent: {
                        title: preClickTitle !== "" ? preClickTitle : undefined,
                        icon: preClickIcon !== "" ? preClickIcon : undefined,
                        contentData: preClickContent !== "" ? preClickContent : undefined,
                    },
                    postClickContent: {
                        title: postClickTitle !== "" ? postClickTitle : undefined,
                        icon: postClickIcon !== "" ? postClickIcon : undefined,
                        contentData: postClickContent !== "" ? postClickContent : undefined,
                    },
                },
                actions: {
                    movement:
                        actionSceneId || actionSceneDelay
                            ? {
                                  sceneId: actionSceneId !== "" ? actionSceneId : undefined,
                                  delay: actionSceneDelay !== 0 ? actionSceneDelay : undefined,
                              }
                            : undefined,

                    navigate: actionNavigate !== "" ? actionNavigate : undefined,
                    appFeedback:
                        actionFeedbackImage ||
                        actionFeedbackVideo ||
                        actionFeedbackAudio ||
                        actionFeedbackInput ||
                        actionProceed
                            ? {
                                  image: actionFeedbackImage !== "" ? actionFeedbackImage : undefined,
                                  video: actionFeedbackVideo !== "" ? actionFeedbackVideo : undefined,
                                  audio: actionFeedbackAudio !== "" ? actionFeedbackAudio : undefined,
                                  input: actionFeedbackInput !== "" ? actionFeedbackInput : undefined,
                                  proceed: actionProceed !== false ? actionProceed : undefined,
                                  delay: actionProceed && actionFeedbackDelay !== 0 ? actionFeedbackDelay : undefined,
                              }
                            : undefined,

                    closeable: actionCloseable ? actionCloseable : undefined,
                },
            };
        }
    }

    function saveHotspot() {
        if (currentHotspot.currentHotspot) {
            //--------------------------------------------------------------------------------------------------------
            // If Answer hotspot
            if (videoCurrentState === "Question") {
                // Creates the hotspot with the new data
                //@ts-ignore
                const newHotspot: IHotspot = createNewHotspot();

                // Create the scenario with the new hotspot
                const newScenario: IScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.map((scene: IScene) =>
                            scene.id === sceneId
                                ? {
                                      ...scene,
                                      hotspots: [
                                          ...scene.hotspots.map((hotspot: IHotspot) =>
                                              hotspot.id === currentQuestion.id
                                                  ? {
                                                        ...hotspot,
                                                        contents: {
                                                            preClickContent: {
                                                                ...hotspot.contents.preClickContent,
                                                                question: {
                                                                    ...hotspot.contents!.preClickContent!.question!,
                                                                    answers: [
                                                                        ...hotspot.contents!.preClickContent!.question!.answers!.map(
                                                                            (answer: IHotspot) =>
                                                                                answer.id ===
                                                                                currentHotspot.currentHotspot!.id
                                                                                    ? newHotspot
                                                                                    : answer,
                                                                        ),
                                                                    ],
                                                                },
                                                            },
                                                        },
                                                    }
                                                  : hotspot,
                                          ),
                                      ],
                                  }
                                : scene,
                        ),
                    ],
                };

                // Pushes the new scenario
                updateScenario(newScenario);
            }
            //--------------------------------------------------------------------------------------------------------
            // If scene hotspot
            else {
                //@ts-ignore
                const newHotspot: IHotspot = createNewHotspot();

                const newScenario: IScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.map((scene: IScene) =>
                            scene.id === sceneId
                                ? {
                                      ...scene,
                                      hotspots: scene.hotspots.map((hotspot: IHotspot) =>
                                          hotspot.id === currentHotspot!.currentHotspot!.id ? newHotspot : hotspot,
                                      ),
                                  }
                                : scene,
                        ),
                    ],
                };

                updateScenario(newScenario);
            }
        }
    }

    return (
        <VTDialog
            disableEnforceFocus={true}
            sx={sx}
            open={openDialog}
            handleClose={() => {
                dispatch(
                    setCurrentHotspot({
                        currentHotspot: undefined,
                        open: false,
                    }),
                );

                dispatch(setEditHotspotDialogIsCurrentlyOpen(false));
            }}
            size="md"
            title="Hotspot editor"
            primaryArea={
                <>
                    <VTButton
                        color={"secondary"}
                        onClick={() =>
                            dispatch(
                                setCurrentHotspot({
                                    currentHotspot: undefined,
                                    open: false,
                                }),
                            )
                        }
                    >
                        Cancel
                    </VTButton>
                    <VTButton
                        onClick={() => {
                            saveHotspot();
                            dispatch(
                                setCurrentHotspot({
                                    currentHotspot: undefined,
                                    open: false,
                                }),
                            );
                        }}
                    >
                        Save
                    </VTButton>
                </>
            }
        >
            <Grid container justifyContent={"space-evenly"} spacing={2} sx={{ height: "100%" }}>
                {/*-----------------------------------------------------------------------------------------------*/}
                {/* Button Switch */}
                {/*-----------------------------------------------------------------------------------------------*/}
                <Grid
                    item
                    container
                    xs={12}
                    sx={{
                        marginTop: "0px",
                        justifyContent: "center",
                        maxHeight: "40px",
                    }}
                >
                    <VTButton
                        onClick={() => setCurrentEditTab("content")}
                        sx={{
                            borderBottom: currentEditTab === "content" ? "3px solid rgb(150, 28, 255)" : "none",
                            maxWidth: "100%",
                            maxHeight: "40px",
                        }}
                        type="underline"
                    >
                        <VTTypography>Content</VTTypography>
                    </VTButton>

                    <VTButton
                        onClick={() => setCurrentEditTab("style")}
                        sx={{
                            borderBottom: currentEditTab === "style" ? "3px solid rgb(150, 28, 255)" : "none",
                            maxWidth: "100%",
                            maxHeight: "40px",
                        }}
                        type="underline"
                    >
                        <VTTypography>Styles</VTTypography>
                    </VTButton>

                    <VTButton
                        onClick={() => setCurrentEditTab("actions")}
                        sx={{
                            borderBottom: currentEditTab === "actions" ? "3px solid rgb(150, 28, 255)" : "none",
                            maxWidth: "100%",
                            maxHeight: "40px",
                        }}
                        type="underline"
                    >
                        <VTTypography>Actions</VTTypography>
                    </VTButton>

                    {/* <VTButton
                        disabled
                        onClick={() => setCurrentEditTab("preview")}
                        sx={{
                            borderBottom:
                                currentEditTab === "preview"
                                    ? "3px solid rgb(150, 28, 255)"
                                    : "none",
                            maxWidth: "100%",
                        }}
                        type="underline"
                    >
                        <VTTypography>Preview</VTTypography>
                    </VTButton> */}
                </Grid>

                {/*-----------------------------------------------------------------------------------------------*/}
                {/* Pre / post click switch */}
                {/*-----------------------------------------------------------------------------------------------*/}
                {(currentEditTab === "content" || currentEditTab === "style") && (
                    <Grid item container xs={2} justifyContent={"center"} alignContent={"center"}>
                        <VTButton
                            sx={{
                                height: "50%",
                                minHeight: "200px",
                                marginRight: "10px",
                                borderRadius: "15px 15px 0px 0px",
                                backgroundColor:
                                    currentPrePostTab === "pre" ? "#7315ce" : theme.palette.background.default,
                                ":hover": {
                                    backgroundColor:
                                        currentPrePostTab === "pre" ? "#9325fe" : theme.palette.background.paper,
                                },
                            }}
                            onClick={() => setCurrentPrePostTab("pre")}
                        >
                            <Grid container justifyContent={"center"} alignContent={"center"}>
                                <Grid item sx={{ color: theme.palette.text.primary }}>
                                    Default
                                </Grid>
                            </Grid>
                        </VTButton>

                        <VTButton
                            sx={{
                                height: "50%",
                                minHeight: "200px",
                                borderRadius: "0px 0px 15px 15px ",
                                marginRight: "10px",
                                backgroundColor:
                                    currentPrePostTab === "post" ? "#7315ce" : theme.palette.background.default,
                                ":hover": {
                                    backgroundColor:
                                        currentPrePostTab === "post" ? "#9325fe" : theme.palette.background.paper,
                                },
                            }}
                            onClick={() => setCurrentPrePostTab("post")}
                        >
                            <Grid container justifyContent={"center"} alignContent={"center"}>
                                <Grid item sx={{ color: theme.palette.text.primary }}>
                                    Clicked
                                </Grid>
                            </Grid>
                        </VTButton>
                    </Grid>
                )}

                <Grid
                    item
                    container
                    xs={currentEditTab === "content" || currentEditTab === "style" ? 10 : 12}
                    justifyContent={"space-evenly"}
                    alignContent={"center"}
                    spacing={1}
                >
                    {/*-----------------------------------------------------------------------------------------------*/}
                    {/* Content  */}
                    {/*-----------------------------------------------------------------------------------------------*/}
                    {currentEditTab === "content" && (
                        <>
                            {currentPrePostTab === "pre" ? (
                                <>
                                    {/* --------------------------------------------------------------- */}
                                    {/* Title */}
                                    <Grid item container xs={9}>
                                        <Grid item>
                                            <VTTypography>Pre-click Title</VTTypography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <VTTextField value={preClickTitle} onChange={(e) => setPreClickTitle(e)} />
                                        </Grid>
                                    </Grid>

                                    {/* --------------------------------------------------------------- */}
                                    {/* Icons */}
                                    <Grid item container xs={3}>
                                        <Grid item>
                                            <VTTypography>Pre-click Icon</VTTypography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Select
                                                value={preClickIcon}
                                                onChange={(e) => setPreClickIcon(e.target.value)}
                                                sx={{
                                                    width: "100%",
                                                    height: "55px",
                                                }}
                                            >
                                                <MenuItem value={"info"}>
                                                    <InfoRounded />
                                                </MenuItem>
                                                <MenuItem value={"movement"}>
                                                    <RadioButtonCheckedRounded />
                                                </MenuItem>
                                                <MenuItem value={"arrows"}>
                                                    <KeyboardDoubleArrowUpRounded />
                                                </MenuItem>
                                                <MenuItem value={""}>{"No Icon"}</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    {/* --------------------------------------------------------------- */}
                                    {/* Title */}
                                    <Grid item container xs={12} sx={{}}>
                                        <Grid item>
                                            <VTTypography>Pre-click Content</VTTypography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <VTRichTextEditor
                                                // @ts-ignore
                                                currentlyDragging={currentlyDraggingObj}
                                                onClickImageIcon={() => {
                                                    dispatch(
                                                        setCurrentSidePanel({
                                                            currentTab: "Images",
                                                            width: window.innerWidth / 4 > 400 ? 400 : 200,
                                                            option: undefined,
                                                        }),
                                                    );
                                                }}
                                                startingHtmlContent={preRichTextEditorInitialContent}
                                                onChange={(htmlStringContent: string) => {
                                                    // if (currentPrePostTab === "post") {
                                                    setPreClickContent(htmlStringContent);
                                                    // } else {
                                                    //   setPreClickContent(htmlStringContent);
                                                    // }
                                                }}
                                                sx={{ height: "350px" }}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    {/* --------------------------------------------------------------- */}
                                    {/* Title */}

                                    <Grid item container xs={9}>
                                        <Grid item>
                                            <VTTypography>Post-click Title</VTTypography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <VTTextField
                                                value={postClickTitle}
                                                onChange={(e) => setPostClickTitle(e)}
                                            />
                                        </Grid>
                                    </Grid>

                                    {/* --------------------------------------------------------------- */}
                                    {/* Icons */}

                                    <Grid item container xs={3}>
                                        <Grid item>
                                            <VTTypography>Post-click Icon</VTTypography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Select
                                                value={postClickIcon}
                                                onChange={(e) => setPostClickIcon(e.target.value)}
                                                sx={{
                                                    width: "100%",
                                                    height: "55px",
                                                }}
                                            >
                                                <MenuItem value={"info"}>
                                                    <InfoRounded />
                                                </MenuItem>
                                                <MenuItem value={"movement"}>
                                                    <RadioButtonCheckedRounded />
                                                </MenuItem>
                                                <MenuItem value={"arrows"}>
                                                    <KeyboardDoubleArrowUpRounded />
                                                </MenuItem>
                                                <MenuItem value={""}>{"No Icon"}</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    {/* --------------------------------------------------------------- */}
                                    {/* Title */}

                                    <Grid item container xs={12}>
                                        <Grid item>
                                            <VTTypography>Post-click Content</VTTypography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {/* <VTTextField
                        value={postClickContent}
                        onChange={(e) => setPostClickContent(e)}
                      /> */}
                                            <VTRichTextEditor
                                                // @ts-ignore
                                                currentlyDragging={currentlyDraggingObj}
                                                onClickImageIcon={() => {
                                                    dispatch(
                                                        setCurrentSidePanel({
                                                            currentTab: "Images",
                                                            width: window.innerWidth / 4 > 400 ? 400 : 200,
                                                            option: undefined,
                                                        }),
                                                    );
                                                }}
                                                startingHtmlContent={postRichTextEditorInitialContent}
                                                onChange={(htmlStringContent: string) => {
                                                    // if (currentPrePostTab === "post") {
                                                    setPostClickContent(htmlStringContent);
                                                    // } else {
                                                    //   setPreClickContent(htmlStringContent);
                                                    // }
                                                }}
                                                sx={{ height: "350px" }}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </>
                    )}

                    {/*-----------------------------------------------------------------------------------------------*/}
                    {/* Style  */}
                    {/*-----------------------------------------------------------------------------------------------*/}
                    {currentEditTab === "style" && (
                        <Grid
                            container
                            sx={{ height: "100%" }}
                            item
                            justifyContent={"space-evenly"}
                            alignContent={"space-evenly"}
                            spacing={1}
                        >
                            {currentPrePostTab === "pre" ? (
                                <>
                                    {/* --------------------------------------------------------------- */}
                                    {/* Title */}
                                    <Grid item container xs={5}>
                                        <Grid item>
                                            <VTButton
                                                type={preClickBackgroundColour !== "" ? "primary" : "underline"}
                                                onClick={() =>
                                                    setPreClickBackgroundColour(
                                                        preClickBackgroundColour === "" ? "#303030" : "",
                                                    )
                                                }
                                                sx={{
                                                    color: preClickTextColour !== "" ? preClickTextColour : "#ffffff",
                                                    backgroundColor: preClickBackgroundColour,
                                                    marginLeft: "-12px",
                                                }}
                                            >
                                                Pre-Click Border colour
                                            </VTButton>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {preClickBackgroundColour !== "" && (
                                                <SketchPicker
                                                    onChange={(e: any) => setPreClickBackgroundColour(e.hex)}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>

                                    {/* <Grid item container xs={5}>
                                        <Grid item>
                                            <VTButton
                                                type={preClickTextColour !== "" ? "primary" : "underline"}
                                                onClick={() =>
                                                    setPreClickTextColour(preClickTextColour === "" ? "#ffffff" : "")
                                                }
                                                sx={{
                                                    color: preClickTextColour !== "" ? preClickTextColour : "#ffffff",
                                                    backgroundColor:
                                                        preClickTextColour !== ""
                                                            ? preClickBackgroundColour !== ""
                                                                ? preClickBackgroundColour
                                                                : "#303030"
                                                            : undefined,
                                                }}
                                            >
                                                Pre-Click Text colour
                                            </VTButton>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {preClickTextColour !== "" && (
                                                <CompactPicker onChange={(e: any) => setPreClickTextColour(e.hex)} />
                                            )}
                                        </Grid>
                                    </Grid> */}

                                    <Grid item container xs={10}>
                                        <Grid item>
                                            <VTTypography>Pre-Click Scale</VTTypography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Slider
                                                defaultValue={1}
                                                getAriaValueText={() => preClickScale.toString()}
                                                step={0.1}
                                                valueLabelDisplay="auto"
                                                max={2}
                                                min={0.5}
                                                value={preClickScale}
                                                onChange={(e) =>
                                                    setPreClickScale(
                                                        // @ts-ignore
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    {/* --------------------------------------------------------------- */}
                                    {/* Title */}

                                    <Grid item container xs={5}>
                                        <Grid item>
                                            <VTButton
                                                type={postClickBackgroundColour !== "" ? "primary" : "underline"}
                                                onClick={() =>
                                                    setPostClickBackgroundColour(
                                                        postClickBackgroundColour === "" ? "#303030" : "",
                                                    )
                                                }
                                                sx={{
                                                    color: postClickTextColour !== "" ? postClickTextColour : "#ffffff",
                                                    backgroundColor: postClickBackgroundColour,
                                                    marginLeft: "-16px",
                                                }}
                                            >
                                                Post-Click Border colour
                                            </VTButton>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {postClickBackgroundColour !== "" && (
                                                <SketchPicker
                                                    onChange={(e: any) => setPostClickBackgroundColour(e.hex)}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>

                                    {/* <Grid item container xs={5}>
                                        <Grid item>
                                            <VTButton
                                                type={postClickTextColour !== "" ? "primary" : "underline"}
                                                onClick={() =>
                                                    setPostClickTextColour(postClickTextColour === "" ? "#ffffff" : "")
                                                }
                                                sx={{
                                                    color: postClickTextColour !== "" ? postClickTextColour : "#ffffff",
                                                    backgroundColor:
                                                        postClickTextColour !== ""
                                                            ? postClickBackgroundColour !== ""
                                                                ? postClickBackgroundColour
                                                                : "#303030"
                                                            : undefined,
                                                }}
                                            >
                                                Post-Click Text colour
                                            </VTButton>
                                        </Grid>
                                        <Grid item xs={10}>
                                            {postClickTextColour !== "" && (
                                                <CompactPicker onChange={(e: any) => setPostClickTextColour(e.hex)} />
                                            )}
                                        </Grid>
                                    </Grid> */}
                                    <Grid item container xs={10}>
                                        <Grid item>
                                            <VTTypography>Post-Click Scale</VTTypography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Slider
                                                defaultValue={1}
                                                getAriaValueText={() => postClickScale.toString()}
                                                step={0.1}
                                                valueLabelDisplay="auto"
                                                max={2}
                                                min={0.5}
                                                value={postClickScale}
                                                onChange={(e) =>
                                                    setPostClickScale(
                                                        // @ts-ignore
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    )}

                    {/*-----------------------------------------------------------------------------------------------*/}
                    {/* Actions  */}
                    {/*-----------------------------------------------------------------------------------------------*/}
                    {currentEditTab === "actions" && (
                        <>
                            {/* --------------------------------------------------------------- */}
                            {/* Title */}
                            <Grid item container xs={10} justifyContent={"space-between"}>
                                {/*-------------------------------------------------------------------------------*/}
                                {/* Move scene */}
                                <Grid item xs={9}>
                                    <VTTypography>Move to scene</VTTypography>
                                </Grid>
                                {actionSceneId ? (
                                    <Grid item xs={3}>
                                        <VTTypography>Delay move to scene</VTTypography>
                                    </Grid>
                                ) : (
                                    <Grid item xs={3}>
                                        <VTTypography>Closeable Content</VTTypography>
                                    </Grid>
                                )}
                                <Grid item xs={8}>
                                    <Select
                                        value={actionSceneId}
                                        onChange={(e) => setActionSceneId(e.target.value)}
                                        sx={{ width: "100%" }}
                                    >
                                        {scenario &&
                                            scenario.scenes.map((scene: IScene) => (
                                                <MenuItem value={scene.id}>{scene.name}</MenuItem>
                                            ))}
                                        <MenuItem value={""}>{"No scene"}</MenuItem>
                                    </Select>
                                </Grid>
                                {actionSceneId ? (
                                    <Grid item xs={3}>
                                        <TextField
                                            type={"number"}
                                            value={actionSceneDelay}
                                            onChange={(e) => {
                                                if (!(parseInt(e.target.value) < 0)) {
                                                    setActionSceneDelay(parseInt(e.target.value));
                                                }
                                            }}
                                        />
                                    </Grid>
                                ) : (
                                    <Grid item xs={3}>
                                        <VTButton
                                            type={actionCloseable ? "primary" : "underline"}
                                            onClick={() => setActionCloseable(!actionCloseable)}
                                            endIcon={actionCloseable ? <DoneRoundedIcon /> : <CloseRoundedIcon />}
                                            sx={{ width: "100%", height: "100%" }}
                                        >
                                            Closeable
                                        </VTButton>
                                    </Grid>
                                )}
                            </Grid>

                            {/* --------------------------------------------------------------- */}
                            {/* Feedback audio */}
                            <Grid item container xs={10}>
                                <Grid item>
                                    <VTTypography>Navigate to Url</VTTypography>
                                </Grid>
                                <Grid item xs={12}>
                                    <VTTextField value={actionNavigate} onChange={(e) => setActionNavigate(e)} />
                                </Grid>
                            </Grid>

                            {/* --------------------------------------------------------------- */}
                            {/* Feedback image */}
                            <Grid item container xs={10}>
                                <Grid item>
                                    <VTTypography>Feedback Image</VTTypography>
                                </Grid>
                                <VTImageDropper
                                    currentImage={actionFeedbackImage}
                                    setCurrentImage={setActionFeedbackImage}
                                />
                                <Grid
                                    xs={12}
                                    container
                                    item
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    marginBottom={"5px"}
                                >
                                    <Typography>Drop and drag an image from the image sidebar</Typography>
                                </Grid>
                            </Grid>

                            {/* --------------------------------------------------------------- */}
                            {/* Feedback video */}
                            <Grid item container xs={10}>
                                <Grid item>
                                    <VTTypography>Feedback Video</VTTypography>
                                </Grid>
                                {/*-------------------------------------------------------------------------------*/}
                                {/* Preview image */}
                                <VTVideoDropper
                                    currentVideo={actionFeedbackVideo}
                                    setCurrentVideo={setActionFeedbackVideo}
                                />
                                <Grid
                                    xs={12}
                                    container
                                    item
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    marginBottom={"5px"}
                                >
                                    <Typography>Drop and drag an video from the video sidebar</Typography>
                                </Grid>
                            </Grid>

                            {/* --------------------------------------------------------------- */}
                            {/* Feedback audio */}
                            <Grid item container xs={10}>
                                <Grid item>
                                    <VTTypography>Feedback Audio</VTTypography>
                                </Grid>
                                {/*-------------------------------------------------------------------------------*/}
                                {/* Preview image */}
                                <VTAudioDropper
                                    currentAudio={actionFeedbackAudio}
                                    setCurrentAudio={handleAudioDropped}
                                />
                                <Grid
                                    xs={12}
                                    container
                                    item
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    marginBottom={"5px"}
                                >
                                    <Typography>Drop and drag an audio from the audio sidebar</Typography>
                                </Grid>
                            </Grid>

                            {/* --------------------------------------------------------------- */}
                            {/* Feedback audio */}
                            <Grid item container xs={10}>
                                <Grid item>
                                    <VTTypography>Question for user input</VTTypography>
                                </Grid>
                                {/*-------------------------------------------------------------------------------*/}
                                {/* Preview image */}
                                <Grid item xs={12}>
                                    <VTTextField
                                        value={actionFeedbackInput}
                                        onChange={(e) => setActionFeedbackInput(e)}
                                        multiline
                                        rows={2}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item container xs={10} justifyContent={"space-evenly"} alignItems={"center"}>
                                {currentQuestion &&
                                    currentQuestion.contents?.preClickContent?.question?.answers &&
                                    currentQuestion.contents?.preClickContent?.question?.answers.includes(
                                        currentHotspot.currentHotspot!,
                                    ) && (
                                        <>
                                            <Grid item>
                                                <VTButton
                                                    type={actionProceed ? "primary" : "underline"}
                                                    onClick={() => setActionProceed(!actionProceed)}
                                                    endIcon={actionProceed ? <DoneRoundedIcon /> : <CloseRoundedIcon />}
                                                >
                                                    Proceed video
                                                </VTButton>
                                            </Grid>

                                            {actionProceed && (
                                                <Grid item>
                                                    <VTTypography>Delay Video Proceed</VTTypography>
                                                    <TextField
                                                        type={"number"}
                                                        value={actionFeedbackDelay}
                                                        onChange={(e) => {
                                                            if (!(parseInt(e.target.value) < 0)) {
                                                                setActionFeedbackDelay(parseInt(e.target.value));
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            )}
                                        </>
                                    )}
                            </Grid>
                        </>
                    )}

                    {/*-----------------------------------------------------------------------------------------------*/}
                    {/* Previews  */}
                    {/*-----------------------------------------------------------------------------------------------*/}
                    {/* {currentHotspot &&
                        currentHotspot.currentHotspot &&
                        currentEditTab === "preview" && (
                            <Grid item xs={11}>
                                <VTCanvas>
                                    <VTHotpsot
                                        hotspot={{
                                            id: currentHotspot.currentHotspot
                                                .id,
                                            location:
                                                currentHotspot?.currentHotspot
                                                    .location,
                                            type: currentHotspot?.currentHotspot
                                                .type,
                                            styles: {
                                                preClickStyles: {
                                                    colours: {
                                                        background:
                                                            preClickBackgroundColour !==
                                                            ""
                                                                ? preClickBackgroundColour
                                                                : undefined,
                                                        text:
                                                            preClickTextColour !==
                                                            ""
                                                                ? preClickTextColour
                                                                : undefined,
                                                    },
                                                    scale:
                                                        preClickScale !== 1
                                                            ? preClickScale
                                                            : undefined,
                                                },
                                                postClickStyles: {
                                                    colours: {
                                                        background:
                                                            postClickBackgroundColour !==
                                                            ""
                                                                ? postClickBackgroundColour
                                                                : undefined,
                                                        text:
                                                            postClickTextColour !==
                                                            ""
                                                                ? postClickTextColour
                                                                : undefined,
                                                    },
                                                    scale:
                                                        postClickScale !== 1
                                                            ? postClickScale
                                                            : undefined,
                                                },
                                            },
                                            contents: {
                                                preClickContent: {
                                                    title:
                                                        preClickTitle !== ""
                                                            ? preClickTitle
                                                            : undefined,
                                                    icon:
                                                        preClickIcon !== ""
                                                            ? preClickIcon
                                                            : undefined,
                                                    contentData:
                                                        preClickContent !== ""
                                                            ? preClickContent
                                                            : undefined,
                                                },
                                                postClickContent: {
                                                    title:
                                                        postClickTitle !== ""
                                                            ? postClickTitle
                                                            : undefined,
                                                    icon:
                                                        postClickIcon !== ""
                                                            ? postClickIcon
                                                            : undefined,
                                                    contentData:
                                                        postClickContent !== ""
                                                            ? postClickContent
                                                            : undefined,
                                                },
                                            },
                                            actions: {
                                                closeable: actionCloseable
                                                    ? actionCloseable
                                                    : undefined,
                                            },
                                        }}
                                        currentAttachedObject={undefined}
                                        setCurrentAttachedObject={() => {}}
                                        videoCurrentState={videoCurrentState}
                                    />
                                </VTCanvas>
                            </Grid>
                        )} */}
                </Grid>
            </Grid>
        </VTDialog>
    );
}
