//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
import { Grid, SxProps, TextField, Tooltip, useMediaQuery } from "@mui/material";
import { VTButton } from "@virtus-tech-repository/virtus-tech-repository";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCurrentAudio, setCurrentSidePanel } from "../../store/slices/current.slice";
import {
    AddRounded,
    CancelRounded,
    DeleteRounded,
    EditRounded,
    SaveRounded,
    ArrowForwardRounded,
    Volcano,
    VolumeUpRounded,
    CloseRounded,
    VolumeOffRounded,
} from "@mui/icons-material";
import { Params, useParams } from "react-router-dom";

// Custom components imports (and hooks and helpers)
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    audio: string;
    handleAudioDelete: (sceneId: string, questionId?: string) => void;
    questionId?: string | undefined;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTCreatorAudioControls({ audio, handleAudioDelete, questionId }: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const { sceneId }: Readonly<Params<string>> = useParams();
    const dispatch = useAppDispatch();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { currentHotspot, currentAudio } = useAppSelector((state) => state.currentReducer);

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    // Render for the tab buttons

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Show creator page tabs options
    return (
        <>
            <Tooltip
                sx={{
                    position: "absolute",
                    top: currentHotspot.currentHotspot ? "85px" : "15px",
                    right: "15px",
                    zIndex: 2,
                }}
                title={currentAudio ? "Mute Audio" : "Play Audio"}
                placement={"left"}
            >
                <Grid item>
                    {currentAudio ? (
                        <VTButton type={"icon"} onClick={() => dispatch(setCurrentAudio(""))}>
                            <VolumeOffRounded />
                        </VTButton>
                    ) : (
                        <VTButton type={"icon"} onClick={() => dispatch(setCurrentAudio(audio))}>
                            <VolumeUpRounded />
                        </VTButton>
                    )}
                </Grid>
            </Tooltip>

            <Tooltip
                sx={{
                    position: "absolute",
                    top: currentHotspot.currentHotspot ? "135px" : "65px",
                    right: "15px",
                    zIndex: 2,
                }}
                title={"Delete Audio"}
                placement={"left"}
            >
                <Grid item>
                    <VTButton type={"icon"} onClick={() => handleAudioDelete(sceneId!, questionId)}>
                        <DeleteRounded />
                    </VTButton>
                </Grid>
            </Tooltip>
        </>
    );
}
