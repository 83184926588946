import * as THREE from "three";
import { VRButton, ARButton, XR, Controllers, Hands, RayGrab, useXREvent } from "@react-three/xr";
import { Canvas } from "@react-three/fiber";
import { Box, Loader } from "@react-three/drei";
import { Suspense, useState } from "react";
import Boxxy from "./Box";
import Video from "./Video";
import { Torso } from "./Torso";
import { TorsoSkeliton } from "./TorsoSkeliton";
import { Lung } from "./Lung";
import { Heart } from "./Heart";
import { Labled } from "./Labled";

export default function VRContainer() {
    return (
        <div>
            <ARButton />
            <Canvas>
                <XR>
                    <Hands />
                    {/* <Controllers /> */}
                    <ambientLight />
                    {/* <Box position={[-1, 1, 0]} scale={0.5} material-color={"red"} /> */}
                    {/* <Boxxy /> */}
                    {/* <Person position={[1, 1, 0]} scale={0.1} /> */}
                    <Video
                        url={
                            "https://dev.medias3.digi-sim-api.co.uk/virtus-tech/video/444ff31b-cf9c-4d4a-91ae-7b5ddf083e8e.mp4"
                        }
                    />

                    <Video
                        url={
                            "https://dev.medias3.digi-sim-api.co.uk/virtus-tech/video/35d004f9-ac92-403c-8d7b-6161a65dad8b.mp4"
                        }
                    />
                    {/* <Suspense>
                        <RayGrab>
                            <Torso position={[0.1, -0.1, 0]} scale={0.2} />
                        </RayGrab>
                    </Suspense>

                    <Suspense>
                        <RayGrab>
                            <TorsoSkeliton position={[-0.1, 0.1, 0]} scale={0.2} />
                        </RayGrab>
                    </Suspense>

                    <Suspense>
                        <RayGrab>
                            <Lung position={[-0.1, 0.1, 0]} scale={1} />
                        </RayGrab>
                    </Suspense>

                    <Suspense>
                        <RayGrab>
                            <Heart position={[-0.1, 0.1, 0]} scale={1} />
                        </RayGrab>
                    </Suspense>

                    <Suspense>
                        <RayGrab>
                            <Labled />
                        </RayGrab>
                    </Suspense> */}
                </XR>
            </Canvas>
            <Loader />
        </div>
    );
}
