//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import Error from "./pages/Error";
import ProtectedRoute from "../auth/ProtectedRoute";
import Viewer from "./pages/Viewer";
import {
    VTButton,
    VTLogo,
    VTMenu,
    VTNavBar,
    VTTextField,
    textCutOffAddElipsis,
} from "@virtus-tech-repository/virtus-tech-repository";
import { Grid, MenuItem, Select, Tooltip, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { PersonRounded, LogoutRounded, SettingsRounded } from "@mui/icons-material";
import { setUserId, setUserOrg } from "../store/slices/user.slice";
import { useEffect, useState } from "react";
import { useAppSelector } from "../store/hooks";
import "../App.css";
import Logo from "../assets/images/Logo.svg";
import { useGetAllScenarioOverviewQuery } from "../services/scenario.service";

// Material ui Imports
// Custom components imports (and hooks and helpers)
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
// Optional variables towards the bottom

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Routing handler
export default function AppRoutes() {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const dispatch = useDispatch();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { email: userEmail, organisation, id: userId } = useAppSelector((state) => state.userReducer);
    const { currentPage } = useAppSelector((state) => state.currentReducer);
    const [adminOverrideId, setAdminOverrideId] = useState("");
    const [adminOverrideOrg, setAdminOverrideOrg] = useState("");

    const [displayUserEmailToolTip, setDisplayUserEmailToolTip] = useState<boolean>(true);

    const [currentScenarioName, setCurrentScenarioName] = useState<string | undefined>();

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    async function handleLogout() {
        await Auth.signOut()
            .then((data) => {
                dispatch(setUserId(""));
            })
            .catch((err) => {
                console.error("Error logging out: ", err);
            });
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    return (
        <>
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Nav Bar */}
            <VTNavBar
                sx={{
                    zIndex: 100,
                    ...(currentPage === "creator" && { background: "linear-gradient(to left, #0ab5a7,#8e25fa)" }),
                    position: "fixed",
                }}
                //------------------------------------------------------------------------------------------
                // Primary nav bar area; logo
                primaryArea={
                    currentPage === "login" ? (
                        <Grid container xs={12} sx={{ alignItems: "center", justifyContent: "center" }}>
                            <Grid item>
                                <img height={60} onClick={() => (window.location.pathname = "/")} src={Logo} />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container xs={6}>
                            <Grid item>
                                <img
                                    style={{ cursor: "pointer" }}
                                    height={60}
                                    onClick={() => (window.location.pathname = "/")}
                                    src={Logo}
                                />
                            </Grid>
                        </Grid>
                    )
                }
                //------------------------------------------------------------------------------------------
                // Alternative nav bar area; settings
                altArea={
                    currentPage !== "login" && (
                        <Grid item container sx={{ alignItems: "center" }} xs={12} justifyContent={"flex-end"}>
                            {/*----------------------------------------------------------------------------------*/}
                            {/* User Settings */}
                            {currentScenarioName && (
                                <Tooltip title={currentScenarioName} placement="left">
                                    <Typography variant="h6" sx={{ marginRight: "15px" }}>
                                        {textCutOffAddElipsis(currentScenarioName, 18)}
                                    </Typography>
                                </Tooltip>
                            )}
                            {organisation === "virtus-tech" && (
                                <Grid item>
                                    <VTMenu
                                        type={"icon"}
                                        toolTipOptions={{
                                            toolTipText: "admin",
                                            toolTipPlacement: "bottom",
                                            // alwaysOpen: true
                                        }}
                                        menuOptions={[
                                            {
                                                component: (
                                                    <VTTextField
                                                        placeholder="user Id"
                                                        value={adminOverrideId}
                                                        onChange={(e: string) => setAdminOverrideId(e)}
                                                        sx={{ padding: "5px" }}
                                                    />
                                                ),
                                            },
                                            {
                                                component: (
                                                    <Select
                                                        value={adminOverrideOrg}
                                                        label="org"
                                                        onChange={(e: any) => setAdminOverrideOrg(e.target.value)}
                                                        sx={{ width: "96%", margin: "5px" }}
                                                    >
                                                        <MenuItem value={"virtus-tech"}>VIRTUS_TECH</MenuItem>
                                                        <MenuItem value={"cardiff-uni"}>CARDIFF_UNI</MenuItem>
                                                        <MenuItem value={"new-collage"}>NEW_COLLAGE</MenuItem>
                                                        <MenuItem value={"wrexham-uni"}>WREXHAM_UNI</MenuItem>
                                                        <MenuItem value={"cardiff-met-uni"}>CARDIFF_MET_UNI</MenuItem>
                                                        <MenuItem value={"zurich-uk"}>ZURICH_UK</MenuItem>
                                                    </Select>
                                                ),
                                            },
                                            {
                                                component: (
                                                    <>
                                                        <VTButton
                                                            onClick={() => {
                                                                dispatch(setUserId(adminOverrideId));
                                                                dispatch(setUserOrg(adminOverrideOrg));
                                                            }}
                                                            sx={{ margin: "5px", width: "96%" }}
                                                        >
                                                            Submit
                                                        </VTButton>
                                                        <VTButton
                                                            onClick={() => {
                                                                // @ts-ignore
                                                                errorMethod();
                                                            }}
                                                            sx={{ margin: "5px", width: "96%" }}
                                                        >
                                                            Submit
                                                        </VTButton>
                                                    </>
                                                ),
                                            },
                                        ]}
                                        sx={{ marginRight: "10px" }}
                                    >
                                        <SettingsRounded />
                                    </VTMenu>
                                </Grid>
                            )}
                            <Grid item>
                                {/* <Tooltip title={userEmail} placement="left"> */}
                                <VTMenu
                                    onClick={() => {
                                        setDisplayUserEmailToolTip(false);
                                    }}
                                    onCloseFunction={() => {
                                        setDisplayUserEmailToolTip(true);
                                    }}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        width: "40px",
                                        height: "40px",
                                        top: "3px",
                                        zIndex: 3,
                                        // "& .MuiPaper-root": {
                                        //     border: "3px solid yellow",
                                        //     zIndex: 10000000
                                        // }
                                    }}
                                    type="icon"
                                    color="primary"
                                    toolTipOptions={{
                                        toolTipText: userEmail,
                                        toolTipPlacement: displayUserEmailToolTip ? "bottom" : "left",

                                        // alwaysOpen: true
                                    }}
                                    menuOptions={[
                                        {
                                            component: (
                                                <VTButton
                                                    type={"list"}
                                                    startIcon={<LogoutRounded />}
                                                    onClick={() => {
                                                        handleLogout();
                                                    }}
                                                >
                                                    Logout
                                                </VTButton>
                                            ),
                                        },
                                    ]}
                                    popOutMenuZIndex={101}
                                >
                                    <PersonRounded />
                                </VTMenu>
                                {/* </Tooltip> */}
                            </Grid>
                        </Grid>
                    )
                }
            />
            <Grid
                sx={{
                    position: "relative",
                    width: "100%",
                    height: "100vh",
                    overflow: "hidden",
                }}
            >
                <BrowserRouter>
                    <Routes>
                        {/*------------------------------------------------------------------------------------*/}
                        {/* Default route to the home page */}
                        <Route
                            path="/"
                            element={
                                <ProtectedRoute>
                                    <Home />
                                </ProtectedRoute>
                            }
                        />

                        {/*------------------------------------------------------------------------------------*/}
                        {/* Route to the Creator page, with scenario id and scene id as params */}
                        <Route
                            path="/Viewer/:scenarioId"
                            element={
                                <ProtectedRoute>
                                    <Viewer />
                                </ProtectedRoute>
                            }
                        >
                            <Route
                                path="/Viewer/:scenarioId/:sceneId"
                                element={
                                    <ProtectedRoute>
                                        <Viewer />
                                    </ProtectedRoute>
                                }
                            ></Route>
                        </Route>

                        {/*------------------------------------------------------------------------------------*/}
                        {/* 404 page error handling */}
                        <Route path="*" element={<Error />} />
                    </Routes>
                </BrowserRouter>
            </Grid>
        </>
    );
}
