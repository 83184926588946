/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 heart.gltf -t
*/

import * as THREE from "three";
import React, { useEffect, useRef } from "react";
import { Html, Text, useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { Button } from "@mui/material";
import { useControls } from "leva";

type GLTFResult = GLTF & {
    nodes: {
        heart: THREE.Mesh;
    };
    materials: {
        ["1"]: THREE.MeshStandardMaterial;
    };
};

function Line({ start, end }: any) {
    const ref: any = useRef();

    useEffect(() => {
        ref.current.geometry.setFromPoints([start, end].map((point) => new THREE.Vector3(...point)));
    }, [start, end]);
    return (
        <line ref={ref}>
            <bufferGeometry />
            <lineBasicMaterial color="#ADD8E6" />
        </line>
    );
}

function AniatedLine({ id, start, end, text }: any) {
    const controller = useControls({
        [`${id}-start-x`]: { value: start[0], min: -100, max: 100 },
        [`${id}-start-y`]: { value: start[1], min: -100, max: 100 },
        [`${id}-start-z`]: { value: start[2], min: -100, max: 100 },

        [`${id}-end-x`]: { value: end[0], min: -100, max: 100 },
        [`${id}-end-y`]: { value: end[1], min: -100, max: 100 },
        [`${id}-end-z`]: { value: end[2], min: -100, max: 100 },
    });

    return (
        <>
            <Line
                start={[controller[`${id}-start-x`], controller[`${id}-start-y`], controller[`${id}-start-z`]]}
                end={[controller[`${id}-end-x`], controller[`${id}-end-y`], controller[`${id}-end-z`]]}
            />

            <Text fontSize={0.1}>{text}</Text>
        </>
    );
}

export function Labled(props: JSX.IntrinsicElements["group"]) {
    const { nodes, materials } = useGLTF("/heart.gltf") as GLTFResult;
    return (
        <>
            {/* <primitive object={new THREE.AxesHelper(500)} /> */}
            {/* <pointLight position={[0, 0, -50]} intensity={2} />
            <pointLight position={[0, 0, 50]} intensity={2} />
            <pointLight position={[-50, 0, 0]} intensity={2} />
            <pointLight position={[50, 0, 0]} intensity={2} />
            <AniatedLine id="1" start={[5.3, 3.5, -1]} end={[10, 20, 0]} text={"Right Coronary Artery"} />
            <AniatedLine id="2" start={[1, -1, -3]} end={[-5, 20, -15]} text={"Left Coronary Artery"} />
            <AniatedLine id="3" start={[4.2, 3, -3]} end={[-5, -10, -20]} text={"Left Anterior Descending Artery"} /> */}

            <group {...props} dispose={null} scale={1}>
                <mesh geometry={nodes.heart.geometry} material={materials["1"]} scale={0.001} />
            </group>
        </>
    );
}

useGLTF.preload("/heart.gltf");
