//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
// Custom components imports (and hooks and helpers)
// Media imports

import { Grid, LinearProgress, Slider, SxProps, TextField, Tooltip } from "@mui/material";
import { VTButton, VTTypography } from "@virtus-tech-repository/virtus-tech-repository";

import PauseRounded from "@mui/icons-material/PauseRounded";
import PlayArrowRounded from "@mui/icons-material/PlayArrowRounded";
import FastRewindRounded from "@mui/icons-material/FastRewindRounded";
import FastForwardRounded from "@mui/icons-material/FastForwardRounded";
import {
    AddRounded,
    CancelRounded,
    SaveRounded,
    DeleteRounded,
    VolumeOffRounded,
    VolumeUpRounded,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { IHotspot } from "../../models/hotspot.models";
import { IScene } from "../../models/scenario.models";
import { setCurrentHotspot } from "../../store/slices/current.slice";
import { useDispatch } from "react-redux";
import { getTimeElapsedInStopWatchFormatString } from "../../utils/getTimeElapsedInStopWatchFormattedString";

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
// Optional variables towards the bottom
interface IProps {
    videoCurrentTime: number;
    videoCurrentState: string | undefined;
    setVideoCurrentState: any;
    video: HTMLVideoElement;
    videoNextQuestion: IHotspot | undefined;
    getNextQuestion: (
        time: number,
        hotspots: IHotspot[],
        setVideoNextQuestion: (nextQuestion: IHotspot | undefined) => void,
    ) => void;
    setVideoNextQuestion: (videoNextQuestion: IHotspot | undefined) => void;
    handleAddStopPoint: (sceneId: string, time: number, setVideoNextQuestion: any) => void;
    scene: IScene;
    handleDeleteStopPoint: (sceneId: string, hotspotId: string) => void;
    currentInterfaceTrue: boolean;
    sx?: SxProps;
    currentQuestionsTimes: number[];
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTCreatorVideoControls({
    videoCurrentTime,
    videoCurrentState,
    setVideoCurrentState,
    video,
    videoNextQuestion,
    getNextQuestion,
    setVideoNextQuestion,
    handleAddStopPoint,
    scene,
    handleDeleteStopPoint,
    sx,
    currentInterfaceTrue,
    currentQuestionsTimes,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const dispatch = useDispatch();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [currentTimeEditing, setCurrentTimeEditing] = useState<boolean>(false);
    const [currentTimeEditInput, setCurrentTimeEditInput] = useState<number>(videoCurrentTime);
    const [currentlyMuted, setCurrentMuted] = useState<boolean>(false);
    const [previousQuestionTime, setPreviousQuestionTime] = useState<number>(0);

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    useEffect(() => {
        if (videoNextQuestion) {
            let currentQuestionIndex = currentQuestionsTimes.findIndex(
                (element: number) => element === videoNextQuestion.contents.preClickContent.question?.time,
            );

            if (currentQuestionIndex === 0) {
                setPreviousQuestionTime(0);
            } else {
                setPreviousQuestionTime(currentQuestionsTimes[currentQuestionIndex - 1]);
            }
        } else if (currentQuestionsTimes.length > 0) {
            setPreviousQuestionTime(currentQuestionsTimes[currentQuestionsTimes.length - 1]);
        } else {
            setPreviousQuestionTime(0);
        }
    }, [videoNextQuestion]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // If page is large render all buttons

    return (
        <Grid
            container
            sx={{
                ...sx,
                position: "absolute",
                bottom: "12px",
                width: currentInterfaceTrue ? "calc(100% - 400px)" : "100%",
                zIndex: 1000,
            }}
        >
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "-30px",
                    borderRadius: "15px",
                }}
            >
                {video.buffered.length >= 1 && (
                    <LinearProgress
                        sx={{
                            width: "calc(100% - " + 100 + "px)",
                            zIndex: 800,
                        }}
                        color="info"
                        variant="determinate"
                        value={(video.buffered.end(video.buffered.length - 1) / video.duration) * 100}
                    />
                )}
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Slider
                    sx={{
                        width: "calc(100% - " + 100 + "px)",
                        zIndex: 1000,
                    }}
                    value={videoCurrentTime}
                    max={video.duration}
                    step={0.001}
                />
            </Grid>

            {/*-------------------------------------------------------------------------------*/}
            {/* Rewind button */}
            <Grid item container xs={12} alignItems={"center"} justifyContent={"center"}>
                {/*-----------------------------------------------------------------------------*/}
                {/* Play / Pause controls */}
                {videoCurrentState === "Playing" ? (
                    <VTButton
                        sx={{ marginRight: "5px" }}
                        type={"icon"}
                        onClick={() => {
                            try {
                                video.pause();
                                setVideoCurrentState("Paused");
                                dispatch(
                                    setCurrentHotspot({
                                        currentHotspot: undefined,
                                        open: false,
                                    }),
                                );
                            } catch {
                                console.error("Video not loaded", scene.id);
                            }
                        }}
                    >
                        <PauseRounded />
                    </VTButton>
                ) : (
                    <VTButton
                        sx={{ marginRight: "5px" }}
                        type={"icon"}
                        onClick={() => {
                            try {
                                if (videoCurrentState !== "Question") {
                                    getNextQuestion(videoCurrentTime, scene.hotspots, setVideoNextQuestion);
                                    setVideoCurrentState("Playing");
                                    video.play();
                                }
                            } catch {
                                console.error("Video not loaded", scene.id);
                            }
                        }}
                    >
                        <PlayArrowRounded />
                    </VTButton>
                )}
                {/* {video && currentlyMuted ? (
                    <VTButton
                        onClick={() => {
                            video.muted = false;
                            localStorage.setItem("video_muted", "false");
                            setCurrentMuted(false);
                        }}
                        sx={{ marginRight: "15px" }}
                        type={"icon"}
                    >
                        <VolumeOffRounded />
                    </VTButton>
                ) : (
                    <VTButton
                        onClick={() => {
                            video.muted = true;
                            localStorage.setItem("video_muted", "true");
                            setCurrentMuted(true);
                        }}
                        sx={{ marginRight: "15px" }}
                        type={"icon"}
                    >
                        <VolumeUpRounded />
                    </VTButton>
                )} */}
                {/*-----------------------------------------------------------------------------*/}
                {/* Fast forward controls */}

                {/*-----------------------------------------------------------------------------*/}
                {/* Current time / duration */}

                <div
                    style={{
                        fontFamily: "Digit",
                        fontSize: "0.9em",
                    }}
                >
                    {getTimeElapsedInStopWatchFormatString(videoCurrentTime * 1000)}
                </div>

                <span
                    style={{
                        fontSize: "1.3",
                        marginLeft: "6px",
                        marginRight: "2px",
                        marginTop: "2px",
                    }}
                >
                    /
                </span>

                <div style={{ fontFamily: "Digit", fontSize: "0.9em" }}>
                    {video.duration ? getTimeElapsedInStopWatchFormatString(video.duration * 1000) : "00:00:00"}
                </div>
            </Grid>
        </Grid>
    );
}
