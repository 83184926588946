export enum EDragContentOptions {
    IMAGE = "images",
    IMAGE_360 = "three_sixty_image",
    AUDIO = "audio",
    VIDEO = "video",
    VIDEO_360 = "three_sixty_video",
    MODEL_3D = "three_d_model",
    MOVEMENT = "movement",
    INFORMATION = "information",
    ANSWER = "answer",
    QUESTION = "question",
    INTERFACE = "interface",
    SCENE = "scene",
}
