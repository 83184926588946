//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IGroup, IUser } from "../models/user.model";

// Custom components imports (and hooks and helpers)

export const UserApi = createApi({
    reducerPath: "user",
    tagTypes: ["users", "groups"],
    baseQuery: fetchBaseQuery({
        // baseUrl: process.env.REACT_APP_IS_DEV
        //   ? `${process.env.PUBLIC_URL}/static`
        //   : process.env.REACT_APP_SCENARIO_API,
        baseUrl: process.env.REACT_APP_ACCOUNTS_API,
    }),
    endpoints: (builder) => ({
        getAllUsers: builder.query<IUser[], void>({
            query: () => ({
                //url: process.env.REACT_APP_IS_DEV ? "users.json" : "users",
                url: "/users/5390ce91-dde4-430c-8578-02cbf04eb77f",
                method: "GET",
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }: IUser) => ({
                              type: "users" as const,
                              id,
                          })),
                          { type: "users", id: "LIST" },
                      ]
                    : [{ type: "users", id: "LIST" }],
        }),
        getAllGroups: builder.query<IGroup[], string>({
            query: (userId) => ({
                url: `/groups/${userId}`,
                method: "GET",
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }: IGroup) => ({
                              type: "groups" as const,
                              id,
                          })),
                          { type: "groups", id: "LIST" },
                      ]
                    : [{ type: "groups", id: "LIST" }],
        }),
    }),
});

export const { useGetAllUsersQuery, useGetAllGroupsQuery } = UserApi;
