//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useEffect, useState } from "react";

// Material ui Imports

// Custom components imports (and hooks and helpers)
import HomeScenarioDialog from "../components/HomeScenarioDialog";
import {
    useDeleteScenarioMutation,
    useGetAllScenarioOverviewQuery,
    useGetScenarioQuery,
    useUpdateScenarioOverviewMutation,
} from "../../services/scenario.service";
import { useGetAllGroupsQuery, useGetAllUsersQuery } from "../../services/users.service";
import { useGetAllImagesQuery } from "../../services/media.service";
import { useGetLanguageQuery } from "../../services/language.service";
import HomeLandingContainer from "../containers/HomeLandingContainer";
import HomeSidePanelContainer from "../containers/HomeSidePanelContainer";
import HomeCardGridContainer from "../containers/HomeCardGridContainer";
import Scenario360ImageCardsContainer from "../containers/Scenes360ImageCardsContainer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { element } from "prop-types";
import { IScenarioOverview } from "../../models/scenario.models";
import { setCurrentSidePanel } from "../../store/slices/current.slice";
import { Grid } from "@mui/material";
import { IUserInGroup } from "../../models/user.model";
import AppsRoundedIcon from "@mui/icons-material/AppsRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { VTLandingOptions, VTTypography } from "@virtus-tech-repository/virtus-tech-repository";
import AssignmentIcon from "@mui/icons-material/Assignment";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import { ITracking } from "../../models/tracking.models";
import { useGetScenarioAttemptsQuery, useGetUserAttemptsQuery } from "../../services/tracking.service";
import { uniq } from "lodash";
import BugFixImage from "../../assets/images/BugFixImage";
import { setUserId } from "../../store/slices/user.slice";
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
// Optional variables towards the bottom

interface IHomeContentWrapperProps {
    userId: string;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
export default function HomeContentWrapper({ userId }: IHomeContentWrapperProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const dispatch = useAppDispatch();

    const { currentSidePanel } = useAppSelector((state) => state.currentReducer);

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const [filteredScenarioData, setFilteredScenarioData] = useState<IScenarioOverview[]>([]);
    const { data, isLoading, error: scenarioOverviewError } = useGetAllScenarioOverviewQuery(userId);

    const groups = useGetAllGroupsQuery(userId);
    const images = useGetAllImagesQuery(userId);
    const language = useGetLanguageQuery();
    const { data: userAttemptedScenarios } = useGetUserAttemptsQuery(userId);

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [searchValue, setSearchValue] = useState<string>("");
    const [filterValue, setFilterValue] = useState<string>("All Scenarios");

    const [scenarioDialog, setScenarioDialog] = useState<IScenarioOverview | undefined>(undefined);

    const [currentPreviewImage, setCurrentPreviewImage] = useState<string>("");

    let filters = [
        {
            title: "All Scenarios",
            icon: (
                <AppsRoundedIcon
                    style={{
                        fontSize: "1.7em",
                    }}
                />
            ),
            disabled: false,
            handleClick: () => {
                setFilterValue("All Scenarios");
            },
        },
        {
            title: "Unattempted",
            icon: (
                <AssignmentIcon
                    style={{
                        fontSize: "1.7em",
                    }}
                />
            ),
            disabled: false,
            handleClick: () => {
                setFilterValue("Unattempted");
            },
        },
        {
            title: "Attempted",
            disabled: false,
            icon: (
                <TaskAltRoundedIcon
                    style={{
                        fontSize: "1.7em",
                    }}
                />
            ),
            handleClick: () => {
                setFilterValue("Attempted");
            },
        },
    ];

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    const [updateScenarioOverview, { isLoading: scenarioUpdate }] = useUpdateScenarioOverviewMutation();
    const [deleteScenarioOverview] = useDeleteScenarioMutation();

    useEffect(() => {
        dispatch(
            setCurrentSidePanel({
                currentTab: "Routes",
                width: 200,
                option: undefined,
            }),
        );
    }, []);

    useEffect(() => {
        let tempDataArray: IScenarioOverview[] = [];
        if (data) {
            data?.forEach(
                (element: IScenarioOverview) =>
                    // @ts-ignore
                    (element.published || element.owner === userId) && tempDataArray.push(element),
            );

            setFilteredScenarioData(tempDataArray);
        }
    }, [data]);

    useEffect(() => {
        console.error("Error get sceanrio overview data: ", userId, scenarioOverviewError);
    }, [scenarioOverviewError]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    function handleSaveScenario(
        currentTitle: string,
        currentDescription: string,
        currentPreviewImage: string,
        currentAdmins: IUserInGroup[],
        currentViewers: string[],
        currentOwner: string,
    ) {
        setScenarioDialog(undefined);

        if (scenarioDialog) {
            updateScenarioOverview({
                ...scenarioDialog,
                title: currentTitle,
                description: currentDescription,
                previewImage: currentPreviewImage,
                admins: currentAdmins.map((group) => group.id),
                viewers: currentViewers,
                owner: currentOwner,
                date: new Date(),
            });
        }
    }

    function handleDuplicateScenario(id: string) {
        if (data) {
            const newData = [...data, data?.filter((element: IScenarioOverview) => element.id === id)];
            //updateScenarioOverview(newData);
        }
    }

    function handleDeleteScenario(id: string, accessId: string[]) {
        if (id) {
            accessId.forEach((element: string) => {
                deleteScenarioOverview({ scenarioId: id, accessId: element });
            });
        }
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // call relevant data from api and get from store
    return (
        <>
            <Grid xs={12} container sx={{ overflowY: "auto", overflowX: "hidden", height: "100%" }}>
                {/*-----------------------------------------------------------------------------------------*/}
                {/* Home landing section; search, filters and new scenario button */}
                <VTLandingOptions
                    reactiveWidth={1000}
                    filters={filters}
                    filterValue={filterValue}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    sideDrawWidth={0}
                />

                {/*-----------------------------------------------------------------------------------------*/}
                {/* Home side panel; page routes, preview image list */}

                {scenarioOverviewError ? (
                    <Grid item xs={12} textAlign={"center"}>
                        <BugFixImage
                            style={{ width: "60vw", minWidth: "300px", maxHeight: "200px" }}
                            color={"#0fffff"}
                        />

                        <VTTypography type="Heading3" sx={{ color: "grey", padding: "10px" }}>
                            Something went wrong please try refreshing
                        </VTTypography>
                        <VTTypography type="Subtitle1" sx={{ color: "grey", padding: "10px" }}>
                            If this error persist please contact support.
                        </VTTypography>
                    </Grid>
                ) : (
                    <HomeCardGridContainer
                        data={
                            filteredScenarioData && filterValue === "Unattempted"
                                ? filteredScenarioData.filter(
                                      (element: IScenarioOverview) => !userAttemptedScenarios?.includes(element.id),
                                  )
                                : filterValue === "Attempted"
                                ? filteredScenarioData.filter(
                                      (element: IScenarioOverview) => userAttemptedScenarios?.includes(element.id),
                                  )
                                : filteredScenarioData
                        }
                        userAttemptedScenarios={userAttemptedScenarios}
                        isLoading={isLoading || scenarioUpdate}
                        leftDrawWidth={0}
                        searchValue={searchValue}
                        setScenarioDialog={setScenarioDialog}
                        handleDeleteScenario={handleDeleteScenario}
                    />
                )}
            </Grid>
        </>
    );
}
