import { LoginContainer } from "../containers/LoginContainer";
import { useEffect } from "react";
import { useAppDispatch } from "../../store/hooks";
import { setCurrentPage } from "../../store/slices/current.slice";

// Only display layouts in pages
export default function LoginPage() {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setCurrentPage("login"));
    }, []);

    return <LoginContainer />;
}
