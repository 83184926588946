//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
import { TextField } from "@mui/material";
// Custom components imports (and hooks and helpers)
import { IDragOptions } from "../../models/current.models";
import { useEffect } from "react";
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    handleMediaDropped: (timelineMedia: IDragOptions, hotspotId?: string) => void;
    currentlyDragging: IDragOptions;
    location: DropPoint;
    bottomDrawWidth: number;
    videoNextQuestionID?: string;
}

// Location component is used for bespoke styling
export enum DropPoint {
    DROP_CANVAS = "drop canvas",
    DROP_TIMELINE = "drop timeline=",
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTCreatorDropContainer({
    handleMediaDropped,
    currentlyDragging,
    location,
    bottomDrawWidth,
    videoNextQuestionID,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Drop container for the creator page
    return (
        <TextField
            value={""}
            onChange={(e) => {
                // handleMediaDropped(currentlyDragging, videoNextQuestionID);
            }}
            onDrop={(e) => {
                handleMediaDropped(currentlyDragging, videoNextQuestionID); // works on chrome/mac also
            }}
            variant="standard"
            sx={{
                position: "absolute",
                top: 0.5,
                right: 0,
                zIndex: 5,
                backgroundColor: "rgba(0,0,0,0.4)",
                border: "3px dashed #7315ce",

                // Styling based on location; dynamically calc height for the canvas and timeline panel
                width: location === DropPoint.DROP_CANVAS ? "calc(100% px)" : "100%",
                // Styling based on location; dynamically calc width for the canvas and timeline panel
                height: location === DropPoint.DROP_CANVAS ? "100%" : "calc(100% - " + 4 + "px)",
            }}
            fullWidth={true}
            inputProps={{
                style: {
                    // Set the content width; effects functionality not styling
                    height:
                        location === DropPoint.DROP_CANVAS
                            ? "calc(100vh - " + 85.5 + "px - " + bottomDrawWidth + "px)"
                            : bottomDrawWidth - 10,
                    color: "rgba(0,0,0,0)",
                    border: "none",
                },
                disableUnderline: true,
            }}
            multiline
        />
    );
}
